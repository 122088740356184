<template>
  <nav
    class="w-full flex items-center justify-between border-t border-gray-200"
  >
    <div class="-mt-px flex w-0 flex-1">
      <PaginationButton
        :disabled="!shouldGoPrevious"
        :on-click="() => onPageChange(currentPage - 1)"
      >
        <ArrowLongLeftIcon
          class="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        Previous
      </PaginationButton>
    </div>
    <div class="hidden sm:-mt-px sm:flex">
      <div class="flex gap-8 items-center text-md text-gray-500">
        <PaginationButton
          v-if="!isFirstPageVisible"
          :key="page"
          :on-click="() => onPageChange(page)"
        >
          1
        </PaginationButton>
        <span v-if="visibleRangeOfPages[0] - 1 > 1" class="pt-4">...</span>
        <PaginationButton
          v-for="page in visibleRangeOfPages"
          :key="page"
          :is-current="page === currentPage"
          :on-click="() => onPageChange(page)"
        >
          {{ page }}
        </PaginationButton>
        <span
          v-if="
            props.totalPages -
              visibleRangeOfPages[visibleRangeOfPages.length - 1] >
            1
          "
          class="pt-4"
          >...</span
        >
        <PaginationButton
          v-if="!isLastPageVisible"
          :key="props.totalPages"
          :on-click="() => onPageChange(props.totalPages)"
        >
          {{ props.totalPages }}
        </PaginationButton>
      </div>
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <PaginationButton
        :disabled="!shouldGoNext"
        :on-click="() => onPageChange(currentPage + 1)"
      >
        Next
        <ArrowLongRightIcon
          class="ml-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </PaginationButton>
    </div>
  </nav>
</template>

<script setup>
import { computed } from "vue";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/vue/20/solid";
import PaginationButton from "@/components/Pagination/PaginationButton.vue";

const LIMITED_VISIBLE_PAGES = 3;
const halfOfLimitedVisiblePages = Math.floor(LIMITED_VISIBLE_PAGES / 2);

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
    default: 1,
  },
  totalPages: Number,
  totalItems: Number,
  onPageChange: Function,
});

const shouldGoPrevious = computed(() => {
  return props.currentPage > 1;
});

const shouldGoNext = computed(() => {
  return props.currentPage < props.totalPages;
});

const shouldShowAllPages = computed(() => {
  return props.totalPages <= LIMITED_VISIBLE_PAGES;
});

const isFirstPageVisible = computed(() => {
  return visibleRangeOfPages.value.includes(1);
});

const isLastPageVisible = computed(() => {
  return visibleRangeOfPages.value.includes(props.totalPages);
});

const isFirstBatch = computed(() => {
  return props.currentPage <= halfOfLimitedVisiblePages;
});

const isLastBatch = computed(() => {
  return props.currentPage >= props.totalPages - halfOfLimitedVisiblePages;
});

const visibleRangeOfPages = computed(() => {
  const pages = [];

  if (shouldShowAllPages.value) {
    for (let i = 1; i <= props.totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }

  if (isFirstBatch.value) {
    for (let i = 1; i <= LIMITED_VISIBLE_PAGES; i++) {
      pages.push(i);
    }
    return pages;
  }

  if (isLastBatch.value) {
    for (
      let i = props.totalPages - LIMITED_VISIBLE_PAGES + 1;
      i <= props.totalPages;
      i++
    ) {
      pages.push(i);
    }
    return pages;
  }

  for (
    let i = props.currentPage - halfOfLimitedVisiblePages;
    i <= props.currentPage + halfOfLimitedVisiblePages;
    i++
  ) {
    pages.push(i);
  }

  return pages;
});
</script>
