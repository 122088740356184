<template>
  <aside
    class="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20"
  >
    <nav class="flex-none px-4 sm:px-6 lg:px-0">
      <ul
        role="list"
        class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
      >
        <li v-for="item in secondaryNavigation" :key="item.name">
          <a
            :href="item.href"
            :class="[
              item.current
                ? 'bg-linen-50 text-brand-600'
                : 'text-gray-700 hover:text-brand-600 hover:bg-linen-50',
              'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold',
            ]"
          >
            <component
              :is="item.icon"
              :class="[
                item.current
                  ? 'text-brand-600'
                  : 'text-gray-400 group-hover:text-brand-600',
                'h-6 w-6 shrink-0',
              ]"
              aria-hidden="true"
            />
            {{ item.name }}
          </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script setup>
import {
  CreditCardIcon,
  CubeIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline";

const secondaryNavigation = [
  { name: "Account", href: "#", icon: UserCircleIcon, current: true },
  { name: "Usage", href: "#", icon: CubeIcon, current: false },
  { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
  { name: "Support", href: "#", icon: UsersIcon, current: false },
];
</script>
