<template>
  <div class="font-mono">
    <Navbar />
    <main
      class="pt-14 mb-auto mx-auto max-w-md sm:max-w-3xl w-full px-6 lg:px-8"
    >
      <div class="mt-8 max-w-7xl mx-auto">
        <h3 class="text-lg leading-6 font-semibold text-gray-900">Profile</h3>
        <p class="mt-1 max-w-2xl text-xs sm:text-base text-gray-900">
          View and update your account information.
        </p>
      </div>
      <div class="mt-5 border-t border-gray-200 max-w-7xl mx-auto">
        <dl class="divide-y divide-gray-200">
          <div
            class="items-center py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4"
          >
            <dt class="text-base font-medium text-gray-900">Email address</dt>
            <dd
              class="items-center mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2"
            >
              <span class="flex-grow flex">
                <span class="truncate mr-2 flex-shrink-0">
                  {{ authStore.user?.email }}
                </span>
                <span
                  v-if="authStore.user?.email && authStore.user?.is_verified"
                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                >
                  verified
                </span>
                <span
                  v-else-if="authStore.user?.email"
                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 cursor-pointer"
                >
                  not verified
                </span>
              </span>
            </dd>
          </div>
          <div
            class="items-center py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
          >
            <dt class="text-base font-medium text-gray-900">
              Summaries so far
            </dt>
            <dd
              v-if="Number.isInteger(authStore.user?.summaries_submitted)"
              class="mt-1 flex flex-col text-base text-gray-900 sm:mt-0 sm:col-span-2"
            >
              <div>
                <span class="flex-grow">
                  {{ authStore.user?.summaries_submitted }} summaries
                </span>
                <router-link
                  :to="`/history`"
                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-brand-100 text-brand-800"
                >
                  View all
                </router-link>
              </div>
              <span class="flex-grow">
                time saved:
                {{
                  formatDurationUsage(authStore.user?.summary_duration_property)
                }}
              </span>
            </dd>
            <SpinnerIcon v-else height="5" width="5" color="black" />
          </div>
          <div
            class="items-center py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
          >
            <dt class="text-base font-medium text-gray-900">
              Available to summarize
            </dt>
            <dd
              v-if="Number.isInteger(authStore.user?.remaining_credit)"
              class="items-center mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2"
            >
              <span class="mr-2 flex-shrink-0">
                {{ formatDurationUsage(authStore.user?.remaining_credit) }}
              </span>
              <button
                @click="
                  showPricing = true;
                  $refs.pricing.scrollIntoView({
                    behavior: 'smooth',
                  });
                "
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-brand-100 text-brand-800"
              >
                + Add
              </button>
            </dd>
            <SpinnerIcon v-else height="5" width="5" color="black" />
          </div>
          <div
            class="items-center py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
          >
            <dt class="text-base font-medium text-gray-900">
              <router-link to="/auth/logout">
                Logout <span aria-hidden="true">&rarr;</span>
              </router-link>
            </dt>
            <dd
              class="items-center mt-1 flex text-base text-gray-900 sm:mt-0 sm:col-span-2"
            ></dd>
          </div>
        </dl>
      </div>
      <div ref="pricing">
        <Pricing v-if="showPricing" :user="authStore.user" :minimal="true" />
      </div>
    </main>

    <MyFooter />
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useHead } from "@unhead/vue";

import Navbar from "@/components/Navbars/Navbar.vue";
import MyFooter from "@/components/Footers/Footer.vue";
import Pricing from "@/components/Contents/Pricing.vue";
import SpinnerIcon from "@/components/Icons/SpinnerIcon.vue";

import { useAuthStore } from "@/stores/authStore";
import { usePaymentStore } from "@/stores/payment/paymentStore";
import { formatDurationUsage } from "@/utils";

const payemntStore = usePaymentStore();
const authStore = useAuthStore();

const showPricing = ref(false);

onMounted(() => {
  authStore.fetchProfile();
  const lastAttemptToBuy = localStorage.getItem("lastAttemptToBuy"); // delete this?
  if (payemntStore.showPricingInProfile) {
    showPricing.value = true;
  }
});

useHead({
  title: "NutshellPro - Account",
});
</script>
