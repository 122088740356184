import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useSummarizerStatusStore } from "@/stores/summarizer/summarizerStatusStore";
import {
  ALERT_TYPE_ERROR,
  ALERT_TYPE_INFO,
  ALERT_ACTION_LARGE_FILE,
} from "../../constants";

export const useSummarizerFormStore = defineStore("summarizerForm", () => {
  const videoUrl = ref("");

  const uploadingFile = ref(false);
  const uploadUrl = ref(null);
  const objectName = ref(null);
  const fileNameToUpload = ref("");

  const resultsStore = useSummarizerResultsStore();
  const statusStore = useSummarizerStatusStore();

  const setVideoUrl = (url) => {
    videoUrl.value = url;
  };

  const setUploadingFile = (isUploadingFile) => {
    uploadingFile.value = isUploadingFile;
  };

  const getUploadUrl = async () => {
    const response = await axios.get("/api/summaries/generate-upload-url");
    uploadUrl.value = response.data["upload_url"];
    objectName.value = response.data["object_name"];
    resultsStore.setVideoInfo({
      streamId: response.data["stream_id"],
    });
  };

  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileSize = selectedFile.size;
      // check if file size is greater than 100MB (+ some slack)
      if (fileSize > 100000000 + 5000000) {
        statusStore.setConnectionStatusMessage(
          "File size is too large. Please upload a file less than 100MB (contact support if you need more)."
        );
        statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
        statusStore.setConnectionErrorAction(ALERT_ACTION_LARGE_FILE);
        resultsStore.updateStream({
          failure_reason: `File size: ${
            Math.round((fileSize / 1000000 + Number.EPSILON) * 100) / 100
          } MB, ${selectedFile.type}`,
        });

        return;
      }
      try {
        statusStore.setConnectionStatusMessage(
          "Upload in progress. Please wait ..."
        );
        statusStore.setConnectionStatusType(ALERT_TYPE_INFO);
        const response = await axios.put(uploadUrl.value, selectedFile, {
          headers: {
            "Content-Type": "video/mp4",
          },
        });

        if (response.status === 200) {
          uploadingFile.value = true;
          fileNameToUpload.value = selectedFile.name;
          resultsStore.handleURLSubmit();
        } else {
          statusStore.setConnectionStatusMessage(
            "Something went wrong with the uploading process. Please try again later."
          );
          statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
        }
      } catch (error) {
        statusStore.setConnectionStatusMessage(
          "Something went wrong with the uploading process. Please try again later."
        );
        statusStore.setConnectionStatusType(ALERT_TYPE_ERROR);
      }
    }
  };

  const initializeState = () => {
    videoUrl.value = "";
    uploadingFile.value = false;
    fileNameToUpload.value = "";
  };

  return {
    videoUrl,
    uploadingFile,
    uploadUrl,
    objectName,
    fileNameToUpload,
    handleFileUpload,
    getUploadUrl,
    initializeState,
    setVideoUrl,
    setUploadingFile,
  };
});
