<script setup>
import { onMounted } from "vue";
import axios from "axios";

import { useRouter, useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useAlertStore } from "@/stores/alertStore";

const authStore = useAuthStore();
const alertStore = useAlertStore();

const router = useRouter();
const route = useRoute();

onMounted(() => {
  let fullCallbackToken = route.fullPath.split("/callback?")[1];
  const options = {
    method: "GET",
    url: "/api/auth/google/callback?" + fullCallbackToken,
  };
  axios(options)
    .then(() => {
      authStore.fetchUser();
    })
    .then(() => {
      alertStore.showSuccess("Logged in successfully.");
      router.push({ name: "Home", query: { fromOAuth: "true" } });
    });
});
</script>
