<template>
  <div class="flex">
    <div title="Like">
      <HandThumbUpIcon
        v-if="!showThumbsUpSolid"
        class="h-5 w-5 mr-1 cursor-pointer"
        @click="submitThumbsUp"
        title="Like"
      />
      <HandThumbUpIconSolid
        v-else
        class="h-5 w-5 mr-1 text-brand-800"
        title="Like"
      />
    </div>
    <div title="Dislike">
      <HandThumbDownIcon
        v-if="!showThumbsDownSolid"
        class="h-5 w-5 mr-1 cursor-pointer"
        @click="submitThumbsDown"
      />
      <HandThumbDownIconSolid v-else class="h-5 w-5 mr-1 text-brand-800" />
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useModalStore } from "@/stores/modalStore";

import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/vue/24/outline";
import {
  HandThumbDownIcon as HandThumbDownIconSolid,
  HandThumbUpIcon as HandThumbUpIconSolid,
} from "@heroicons/vue/24/solid";

const resultsStore = useSummarizerResultsStore();
const modalStore = useModalStore();

const showThumbsUpSolid = ref(false);
const showThumbsDownSolid = ref(false);

const submitThumbsUp = async () => {
  showThumbsUpSolid.value = true;
  showThumbsDownSolid.value = false;
  resultsStore.updateStream({ thumbs_up: true });
};
const submitThumbsDown = async () => {
  showThumbsDownSolid.value = true;
  showThumbsUpSolid.value = false;
  resultsStore.updateStream({ thumbs_up: false });
  modalStore.displayModal({
    title: "Feedback",
    text: "What was the issue with the summary? How could it be improved?",
    textField: true,
    submitButton: true,
    action: (userFeedback) =>
      resultsStore.updateStream({ user_feedback: userFeedback }),
  });
};

const props = defineProps({
  text: {
    type: String,
    default: "",
  },
});
</script>
