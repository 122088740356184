<template>
  <div
    v-if="visible"
    :class="[
      'fixed top-0 right-0 mt-4 mr-4 p-4 rounded-md z-[100]',
      type === 'success' ? 'bg-green-50' : 'bg-red-50',
    ]"
    class="  "
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <CheckCircleIcon
          :class="[
            'h-5 w-5',
            type === 'success' ? 'text-green-400' : 'text-red-400',
          ]"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <p
          :class="[
            'text-sm font-medium',
            type === 'success' ? 'text-green-800' : 'text-red-800',
          ]"
        >
          {{ message }}
        </p>
      </div>
      <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            @click="hideAlert"
            type="button"
            :class="[
              'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
              type === 'success'
                ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
                : 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
            ]"
            class="       "
          >
            <span class="sr-only">Dismiss</span>
            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckCircleIcon, XMarkIcon } from "@heroicons/vue/20/solid";

import { computed } from "vue";

import { useAlertStore } from "@/stores/alertStore";

const alertStore = useAlertStore();

const visible = computed(() => alertStore.visible);
const message = computed(() => alertStore.message);
const type = computed(() => alertStore.type);

function hideAlert() {
  alertStore.hide();
}
</script>
