<template>
  <div
    class="mx-auto max-w-2xl lg:max-w-5xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 mt-12 lg:px-8 lg:pb-32"
  >
    <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
      Frequently asked questions
    </h2>
    <dl class="mt-10 space-y-4 divide-y divide-gray-900/10">
      <Disclosure
        as="div"
        v-for="faq in faqs"
        :key="faq.question"
        class="pt-4"
        v-slot="{ open }"
      >
        <dt>
          <DisclosureButton
            class="flex w-full items-start justify-between text-left text-gray-900"
          >
            <span class="text-base font-semibold leading-7">
              {{ faq.question }}
            </span>
            <span class="ml-6 flex h-7 items-center">
              <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
              <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
            </span>
          </DisclosureButton>
        </dt>
        <DisclosurePanel as="dd" class="mt-2 pr-12">
          <p class="text-base leading-7 text-gray-600">{{ faq.answer }}</p>
        </DisclosurePanel>
      </Disclosure>
    </dl>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/vue/24/outline";

const faqs = ref([
  {
    id: 1,
    question: "How does NutshellPro work?",
    answer:
      "NutshellPro uses advanced algorithms to analyze video and audio content, identifying key moments and extracting essential information. It then compiles this data into concise summaries, making it easy for you to grasp the main points of the video and audio.",
  },
  {
    id: 2,
    question: "How do you ensure the quality of the summaries provided?",
    answer:
      "We continuously refine and train our algorithms to maintain the highest quality summaries. Our technology evolves to better understand context and user preferences. User feedback also plays a crucial role in enhancing our summarization accuracy.",
  },
  {
    id: 3,
    question:
      "What types of videos and audios can I summarize with NutshellPro?",
    answer:
      "You can summarize videos and audios from a wide range of websites, including YouTube, Vimeo, Google Drive, Dropbox, Facebook, Twitter, Telegram, and more. Additionally, you can also summarize local video and audio files from your laptop or phone.",
  },
  {
    id: 4,
    question:
      "Is there a limit to the length of videos and audios I can summarize?",
    answer:
      "NutshellPro allows you to summarize videos and audios of up to three hours in length. Whether it's a short clip or a lengthy lecture, we've got you covered.",
  },
  {
    id: 5,
    question: "How do the free credits work?",
    answer:
      "Every new user receives free credits that enable one-hour summarization. It's our way of letting you experience the service. If you need more summarization time, you can purchase additional credits.",
  },
  {
    id: 6,
    question: "Is my data and information secure with NutshellPro?",
    answer:
      "Yes, we take data security seriously. Your information and summaries are kept secure and private. We do not share or sell your data to third parties.",
  },
  {
    id: 7,
    question: "Which websites are supported?",
    answer:
      "We currently support popular platforms like YouTube, Vimeo, Google Drive, Dropbox, Facebook, Twitter, and Telegram, and we're actively working on expanding our website support. If there's a specific website you'd like us to support, please contact us, and we'll do our best to accommodate your request.",
  },
  {
    id: 8,
    question: "What does 'hours of video/audio summarization' mean?",
    answer:
      "It refers to the cumulative duration of the videos and audios you've summarized using NutshellPro. For example, if you've summarized two videos or audios, each with a 30-minute duration, you've used 1 hour of video/audio summarization from your account.",
  },
  {
    id: 9,
    question: "How long can the videos and audios I'm summarizing be?",
    answer:
      "You can summarize videos and audios ranging from a few minutes to a few hours in length. The maximum duration for a single video or audio summary is 3 hours. If you have longer content to summarize, please reach out to us, and we'll work with you to meet your needs.",
  },
  {
    id: 10,
    question: "I want to summarize a local file. Is that possible?",
    answer:
      "Absolutely! While we're working on adding the functionality to directly upload local files, in the meantime, you can upload the file to a cloud platform like Google Drive or Dropbox. Once it's there, simply add the shared link in our app to summarize the file. We're making it convenient for you to access your local content.",
  },
  {
    id: 11,
    question: "Do you offer business plans or enterprise accounts?",
    answer:
      "Yes, we do! NutshellPro offers business plans and enterprise accounts tailored to the needs of organizations. Contact our support team to learn more about our business solutions and to discuss custom packages that suit your company's requirements.",
  },
]);
</script>
