<template>
  <div
    class="mx-auto mt-36 sm:mt-20 lg:mt-24 max-w-2xl lg:max-w-none px-6 lg:px-8"
  >
    <dl
      class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3"
    >
      <div
        v-for="feature in features"
        :key="feature.name"
        class="flex flex-col sm:mt-0 -mt-6"
      >
        <dt
          class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"
        >
          <component
            :is="feature.icon"
            class="h-5 w-5 flex-none text-brand-600"
            aria-hidden="true"
          />
          {{ feature.name }}
        </dt>
        <dd
          class="mt-2 flex flex-auto flex-col text-base leading-7 text-gray-900"
        >
          <p class="flex-auto">{{ feature.description }}</p>
        </dd>
      </div>
    </dl>
  </div>
</template>
<script setup>
import {
  LightBulbIcon,
  CursorArrowRaysIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/24/outline";

import { ref } from "vue";

const features = ref([
  {
    name: "Summarize from Many Websites",
    description:
      "You can summarize videos and audios from a wide range of websites, including popular platforms like YouTube, Vimeo, Facebook, Google Drive, Dropbox, Twitter, Telegram, and more.",
    icon: LightBulbIcon,
  },
  {
    name: "Your Local Videos and Audios, Summarized",
    description:
      "Have videos and audios on your laptop or phone? NutshellPro lets you summarize local video and audio files, making it simple to extract key takeaways from your own content.",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Summarize Lengthy Video and Audio Files",
    description:
      "With NutshellPro, you can summarize videos and audios that are up to three hours long, whether it's an in-depth lecture or a lengthy meeting recording.",
    icon: ShieldCheckIcon,
  },
]);
</script>
