/* Head */
export const TITLE = "NutshellPro: Summarize Any Video and Audio File";

/* Response Types */
export const BRIEF_SUMMARY = "brief_summary";
export const KEY_TAKEAWAYS = "key_takeaways";
export const CHAPTER_SUMMARY = "chapter_summary";
export const END_OF_STREAM = "end_of_stream";
export const VIDEO_INFO = "video_info";
export const SUMMARY_SLUG = "summary_slug";
export const CHAPTER_INFO = "chapter_info";
export const PROGRESS_UPDATE = "progress_update";
export const NO_VIDEO_FOUND = "no_video_found";
export const ERROR_PROCESSING_VIDEO = "error_processing_video";
export const USAGE_EXCEEDED = "usage_exceeded";
export const INVALID_URL = "invalid_url";

/* Stream Text Types */
export const END_OF_CHAPTER = "<endofchapter>";

/* Form Alert Actions */
export const ALERT_ACTION_UPGRADE_ACCOUNT = "upgrade_account";
export const ALERT_ACTION_LARGE_FILE = "large_file";

/* Form Alert Types */
export const ALERT_TYPE_INFO = "info";
export const ALERT_TYPE_WARNING = "warning";
export const ALERT_TYPE_ERROR = "error";
export const ALERT_TYPE_SUCCESS = "success";
