<template>
  <div id="app" class="bg-linen-50">
    <router-view />
    <Alert />
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { useAuthStore } from "@/stores/authStore";

import Alert from "@/components/Utils/Alert.vue";

const authStore = useAuthStore();
const route = useRoute();

onMounted(async () => {
  const fromOAuth = route.query.fromOAuth === "true";
  if (!fromOAuth) {
    await authStore.fetchUser().then(() => {
      authStore.authChecked = true;
    });
  }
});
</script>
