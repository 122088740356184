<template>
  <div>
    <span
      ref="buttonsref"
      class="sticky mx-4 py-6 justify-center isolate inline-flex rounded-md shadow-sm"
    >
      <button
        @click="$refs.usersref.scrollIntoView({ behavior: 'smooth' })"
        type="button"
        class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Users
      </button>
      <button
        @click="$refs.streamsref.scrollIntoView({ behavior: 'smooth' })"
        type="button"
        class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Streams
      </button>
      <button
        @click="$refs.summariesref.scrollIntoView({ behavior: 'smooth' })"
        type="button"
        class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Summaries
      </button>
      <button
        @click="$refs.ordersref.scrollIntoView({ behavior: 'smooth' })"
        type="button"
        class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Orders
      </button>
    </span>
    <div
      ref="usersref"
      class="bg-gray-100 mx-4 py-6 flex flex-col justify-center"
    >
      <div class="relative">
        <div class="relative px-4 py-10 bg-white shadow rounded sm:p-10">
          <UserList :users="users" />
        </div>
      </div>
    </div>
    <div
      ref="streamsref"
      class="bg-gray-100 mx-4 py-6 flex flex-col justify-center"
    >
      <div class="relative">
        <div class="relative px-4 py-10 bg-white shadow rounded sm:p-10">
          <StreamList :streams="streams" />
        </div>
      </div>
    </div>
    <div
      ref="summariesref"
      class="bg-gray-100 mx-4 py-6 flex flex-col justify-center"
    >
      <div class="relative">
        <div class="relative px-4 py-10 bg-white shadow rounded sm:p-10">
          <SummaryList :summaries="summaries" />
        </div>
      </div>
    </div>
    <div
      ref="ordersref"
      class="bg-gray-100 mx-4 py-6 flex flex-col justify-center"
    >
      <div class="relative">
        <div class="relative px-4 py-10 bg-white shadow rounded sm:p-10">
          <OrderList :orders="orders" />
        </div>
      </div>
    </div>
    <button
      @click="$refs.buttonsref.scrollIntoView({ behavior: 'smooth' })"
      type="button"
      class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
      Back to top
    </button>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useHead } from "@unhead/vue";

import { useAuthStore } from "@/stores/authStore";
import UserList from "@/components/Admin/UserList.vue";
import OrderList from "@/components/Admin/OrderList.vue";
import SummaryList from "@/components/Admin/SummaryList.vue";
import StreamList from "@/components/Admin/StreamList.vue";

const authStore = useAuthStore();
const users = ref([]);
const orders = ref([]);
const questions = ref([]);
const summaries = ref([]);
const streams = ref([]);

onMounted(async () => {
  await fetchUsers();
  await fetchOrders();
  await fetchSummaries();
  await fetchStreams();
});

const fetchUsers = async () => {
  const response = await axios.get("/api/users/get/all");
  users.value = response.data;
};

const fetchOrders = async () => {
  const response = await axios.get("/api/orders/get/all");
  orders.value = response.data;
};

const fetchStreams = async () => {
  const response = await axios.get("/api/streams/get/all");
  streams.value = response.data;
};

const fetchSummaries = async () => {
  const response = await axios.get("/api/summaries/get/all");
  summaries.value = response.data;
};

defineExpose({
  users,
  orders,
  questions,
  summaries,
  streams,
  fetchUsers,
});

useHead({
  title: "NutshellPro - Account",
});
</script>
