/* global Paddle */
import { defineStore } from "pinia";
import axios from "axios";
import { router } from "@/main";

export const usePaddleStore = defineStore("paddle", {
  state: () => ({
    paddleLoaded: false,
  }),
  actions: {
    async loadPaddle() {
      if (this.paddleLoaded) return;

      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://cdn.paddle.com/paddle/paddle.js";
        script.async = true;
        script.onload = () => {
          if (process.env.VUE_APP_ENVIRONMENT === "development") {
            Paddle.Environment.set("sandbox");
          }
          Paddle.Setup({
            vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID),
            // eventCallback: function (data) {
            //   console.log(data);
            //   // The data.event will specify the event type
            //   // All events: https://developer.paddle.com/reference/84fbd65638c3f-checkout-events
            //   if (data.event === "Checkout.Complete") {
            //     console.log(data.eventData); // Data specifics on the event
            //   } else if (data.event === "Checkout.Close") {
            //     console.log(data.eventData); // Data specifics on the event
            //   }
            // },
          });
          this.paddleLoaded = true;
          resolve();
        };
        document.body.appendChild(script);
      });
    },
    getMySubscriptionStatus() {
      const options = {
        url: `/api/subscriptions/me`,
        method: "GET",
      };
      return axios(options)
        .then((response) => {
          // TODO: add a success alert
          router.push({ name: "SummarizationHome" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
