<template>
  <div
    class="mt-10 grid grid-cols-1 sm:flex items-center justify-center gap-x-1 sm:gap-x-6"
  >
    <router-link
      @click="handleClick"
      :to="authStore.user?.is_authenticated ? '/s' : '/auth/login'"
      class="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
    >
      Get Started
    </router-link>
  </div>
</template>
<script setup>
import { inject } from "vue";
const saEvent = inject("saEvent");
const handleClick = () => {
  saEvent("click_button_get_started_hero");
};

import { useAuthStore } from "@/stores/authStore";
const authStore = useAuthStore();
</script>
