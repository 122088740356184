<template>
  <div>
    <Navbar />
    <main
      class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8"
    >
      <div class="text-center">
        <p class="text-base font-semibold text-brand-600">404</p>
        <h1
          class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
        >
          Page not found
        </h1>
        <p class="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <router-link
            to="/"
            class="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
          >
            Go back home
          </router-link>
          <a
            href="https://tally.so/r/3E5zVl"
            target="_blank"
            class="text-sm font-semibold text-gray-900"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>
<script>
import error404 from "@/assets/img/404.png";

import MyFooter from "@/components/Footers/Footer.vue";
import Navbar from "@/components/Navbars/Navbar.vue";

export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    MyFooter,
  },
  setup() {
    return {
      error404,
    };
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1em;
}
p {
  margin-bottom: 1em;
}
ul {
  margin-left: 1em;
}
li {
  margin-bottom: 0.5em;
  list-style-type: disc;
}
</style>
