<template>
  <div class="font-mono">
    <Navbar />
    <main class="isolate">
      <div class="relative pt-14">
        <div class="py-24 sm:py-32">
          <div class="mx-auto max-w-md sm:max-w-3xl px-6 lg:px-8">
            <div class="mx-auto text-left">
              <div>
                <div class="text-center">
                  <h2
                    class="mt-2 text-2xl font-semibold leading-6 text-gray-900"
                  >
                    Summarize Effortlessly
                  </h2>
                  <p class="mt-1 text-base text-gray-900">
                    Add a video or audio from a
                    <abbr
                      @click="
                        modalStore.displayModal({
                          title:
                            'You can add videos and audios from web addresses',
                          text: 'like youtube, vimeo, facebook, google drive, dropbox, soundcloud, twitter, telegram, and many more',
                        })
                      "
                      title="like youtube, vimeo, facebook, google drive, dropbox, soundcloud, twitter, telegram, and many more"
                      class="cursor-help"
                    >
                      web address
                    </abbr>
                    or a local file.
                  </p>
                </div>
                <SummarizerForm />
              </div>
              <div class="text-left">
                <div class="lg:col-span-7 mt-8">
                  <SummarizerHistory />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </main>
    <MyFooter />
  </div>
</template>
<script setup>
import { useHead } from "@unhead/vue";
import { onMounted, onBeforeMount, watch } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useModalStore } from "@/stores/modalStore";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useSummarizerFormStore } from "@/stores/summarizer/summarizerFormStore";
import { useSummarizerStatusStore } from "@/stores/summarizer/summarizerStatusStore";
import { TITLE } from "@/constants";

import Navbar from "@/components/Navbars/Navbar.vue";
import Modal from "@/components/Utils/Modal.vue";
import SummarizerForm from "@/components/Summarizer/SummarizerForm.vue";
import SummarizerHistory from "@/components/Summarizer/SummarizerHistory.vue";
import MyFooter from "@/components/Footers/Footer.vue";

const router = useRouter();

const authStore = useAuthStore();
const modalStore = useModalStore();
const resultsStore = useSummarizerResultsStore();
const formStore = useSummarizerFormStore();
const statusStore = useSummarizerStatusStore();

const intilizaeComponent = () => {
  if (typeof resultsStore.closeEventSource === "function") {
    resultsStore.closeEventSource();
  }
  formStore.initializeState();
  if (!localStorage.getItem("lastAttemptToBuy")) {
    statusStore.initializeState();
  }
};

onBeforeMount(() => {
  intilizaeComponent();
});

onMounted(async () => {
  if (localStorage.getItem("lastAttemptToBuy")) {
    router.push({ name: "Home" });
  }
});

watch(
  () => authStore.authChecked,
  (authCheckedValue) => {
    if (authStore.authChecked) {
      if (!authStore.user?.is_authenticated) {
        router.push({ name: "Home" });
      }
    }
  },
  { immediate: true }
);

useHead({
  title: TITLE,
});
</script>
