<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
    <div data-custom-class="body">
      <div align="center" style="text-align: left; line-height: 1">
        <div align="center" style="text-align: left; line-height: 150%">
          <div align="center" style="text-align: center; line-height: 150%">
            <div align="center" style="line-height: 22.5px">
              <div align="center" style="line-height: 150%">
                <div
                  align="center"
                  data-custom-class="title"
                  style="text-align: left; line-height: 1.5"
                >
                  <strong>
                    <span style="line-height: 22.5px; font-size: 26px">
                      TERMS OF USE
                    </span>
                  </strong>
                </div>
                <div
                  align="center"
                  data-custom-class="subtitle"
                  style="text-align: left; line-height: 22.5px"
                >
                  <br />
                </div>
                <div
                  align="center"
                  data-custom-class="subtitle"
                  style="text-align: left; line-height: 1.5"
                >
                  <span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                    ><strong>Last updated November 06, 2023</strong></span
                  >
                </div>
              </div>
            </div>
            <div align="center" style="line-height: 17.25px; text-align: left">
              <br />
            </div>
            <div align="center" style="line-height: 17.25px; text-align: left">
              <br />
            </div>
            <div align="center" style="line-height: 17.25px; text-align: left">
              <br />
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(127, 127, 127)"
                  ><span style="color: rgb(0, 0, 0)"
                    ><strong
                      ><span data-custom-class="heading_1"
                        >TABLE OF CONTENTS</span
                      ></strong
                    ></span
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left"><br /></div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#agreement"
                  ><span style="color: rgb(89, 89, 89)"
                    >1. AGREEMENT TO TERMS</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#ip"
                  ><span style="color: rgb(89, 89, 89)"
                    >2. INTELLECTUAL PROPERTY RIGHTS</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#userreps"
                    >3. USER REPRESENTATIONS</a
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#userreg"
                  ><span style="color: rgb(89, 89, 89)"
                    >4. USER REGISTRATION</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#prohibited"
                  ><span style="color: rgb(89, 89, 89)"
                    >5. PROHIBITED ACTIVITIES</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#ugc"
                  ><span style="color: rgb(89, 89, 89)"
                    >6. USER GENERATED CONTRIBUTIONS</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#license"
                    >7. CONTRIBUTION LICENSE</a
                  ></span
                ></span
              >
            </div>
            <div align="center" style="text-align: left"><br /></div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#submissions"
                    >8. SUBMISSIONS</a
                  ></span
                ></span
              >
            </div>
            <div align="center" style="text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#thirdparty"
                  ><span style="color: rgb(89, 89, 89)"
                    >9. THIRD-PARTY WEBSITE AND CONTENT</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#advertisers"
                  ><span style="color: rgb(89, 89, 89)"
                    >10. ADVERTISERS</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#sitemanage"
                    >11. SITE MANAGEMENT</a
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#privacypolicy1"
                  ><span style="color: rgb(89, 89, 89)"
                    >12. PRIVACY POLICY</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#terms"
                  ><span style="color: rgb(89, 89, 89)"
                    >13. TERM AND TERMINATION</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#modifications"
                  ><span style="color: rgb(89, 89, 89)"
                    >14. MODIFICATIONS AND INTERRUPTIONS</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#law"
                  ><span style="color: rgb(89, 89, 89)"
                    >15. GOVERNING LAW</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#disputes"
                  ><span style="color: rgb(89, 89, 89)"
                    >16. DISPUTE RESOLUTION</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#corrections"
                    >17. CORRECTIONS</a
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#disclaimer"
                  ><span style="color: rgb(89, 89, 89)">18. DISCLAIMER</span></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#liability"
                  ><span style="color: rgb(89, 89, 89)"
                    >19. LIMITATIONS OF LIABILITY</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#indemnification"
                    >20. INDEMNIFICATION</a
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#userdata"
                  ><span style="color: rgb(89, 89, 89)">21. USER DATA</span></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#electronic"
                  ><span style="color: rgb(89, 89, 89)"
                    >22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                    SIGNATURES</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#california"
                    >23. CALIFORNIA USERS AND RESIDENTS</a
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><a data-custom-class="link" href="#misc"
                  ><span style="color: rgb(89, 89, 89)"
                    >24. MISCELLANEOUS</span
                  ></a
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#contact"
                    >25. CONTACT US</a
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left">
              <span style="font-size: 15px"
                ><span style="color: rgb(89, 89, 89)"
                  ><a data-custom-class="link" href="#refund"
                    >26. REFUND</a
                  ></span
                ></span
              >
            </div>
            <div style="line-height: 1.5; text-align: left"><br /></div>
            <div style="line-height: 1.5; text-align: left"><br /></div>
          </div>
          <div
            data-custom-class="heading_1"
            id="agreement"
            style="line-height: 115%"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                >1. AGREEMENT TO TERMS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span style="font-size: 15px; line-height: 115%; color: #595959">
              These Terms of Use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity
              (&ldquo;you&rdquo;) and Four Winds Software
              (&quot;<strong>NutshellPro</strong>&quot;,&quot;<strong>Company</strong>&quot;,
              &ldquo;<strong>we</strong>&quot;,
              &ldquo;<strong>us</strong>&quot;, or
              &ldquo;<strong>our</strong>&rdquo;), concerning your access to and
              use of the
              <a
                data-custom-class="link"
                href="https://nutshellpro.com"
                target="_blank"
                >https://nutshellpro.com</a
              >
              website as well as any other media form, media channel, mobile
              website or mobile application related, linked, or otherwise
              connected thereto (collectively, the &ldquo;Site&rdquo;).</span
            >
            You agree that by accessing the Site, you have read, understood, and
            agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
            WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED
            FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span style="font-size: 15px; line-height: 115%; color: #595959"
              >Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms of Use from time
              to time. We will alert you about any changes by updating the
              &ldquo;Last updated&rdquo; date of these Terms of Use, and you
              waive any right to receive specific notice of each such change.
              Please ensure that you check the applicable Terms every time you
              use our Site so that you understand which Terms apply. You will be
              subject to, and will be deemed to have been made aware of and to
              have accepted, the changes in any revised Terms of Use by your
              continued use of the Site after the date such revised Terms of Use
              are posted.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span style="font-size: 15px; line-height: 115%; color: #595959"
              >The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable.</span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span style="font-size: 15px; line-height: 115%; color: #595959"
              >The Site is not tailored to comply with industry-specific
              regulations (Health Insurance Portability and Accountability Act
              (HIPAA), Federal Information Security Management Act (FISMA),
              etc.), so if your interactions would be subjected to such laws,
              you may not use this Site. You may not use the Site in a way that
              would violate the Gramm-Leach-Bliley Act (GLBA).</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                >The Site is intended for users who are at least 18 years old.
                Persons under the age of 18 are not permitted to use or register
                for the Site.</span
              ></span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div style="line-height: 1.5"><br /></div>
          <div data-custom-class="heading_1" id="ip" style="line-height: 1.5">
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    >2.&nbsp;</span
                  ></strong
                >INTELLECTUAL PROPERTY RIGHTS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span style="font-size: 15px; line-height: 115%; color: #595959"
              >Unless otherwise indicated, the Site is our proprietary property
              and all source code, databases, functionality, software, website
              designs, audio, video, text, photographs, and graphics on the Site
              (collectively, the &ldquo;Content&rdquo;) and the trademarks,
              service marks, and logos contained therein (the
              &ldquo;Marks&rdquo;) are owned or controlled by us or licensed to
              us, and are protected by copyright and trademark laws and various
              other intellectual property rights and unfair competition laws of
              the United States, international copyright laws, and international
              conventions. The Content and the Marks are provided on the Site
              &ldquo;AS IS&rdquo; for your information and personal use only.
              Except as expressly provided in these Terms of Use, no part of the
              Site and no Content or Marks may be copied, reproduced,
              aggregated, republished, uploaded, posted, publicly displayed,
              encoded, translated, transmitted, distributed, sold, licensed, or
              otherwise exploited for any commercial purpose whatsoever, without
              our express prior written permission.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span style="font-size: 15px; line-height: 115%; color: #595959"
              >Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="userreps"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >3.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >USER REPRESENTATIONS</span
              ></strong
            >
          </div>
          <div style="line-height: 1"><br /></div>
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div style="text-align: justify; line-height: 115%">
            <div
              data-custom-class="body_text"
              style="line-height: 1.5; text-align: left"
            >
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >By using the Site, you represent and warrant that:&nbsp;</span
              ><span style="color: rgb(89, 89, 89); font-size: 15px">(</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >1</span
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                >) all registration information you submit will be true,
                accurate, current, and complete; (</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >2</span
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                >) you will maintain the accuracy of such information and
                promptly update such registration information as necessary<span
                  style="color: rgb(89, 89, 89); font-size: 15px"
                  >;</span
                >
                <span style="color: rgb(89, 89, 89); font-size: 15px">(</span
                ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                  >3</span
                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                  >) you have the legal capacity and you agree to comply with
                  these Terms of Use;</span
                ></span
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                >&nbsp;(</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >4</span
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                >) you are not a minor in the jurisdiction in which you reside;
                (</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >5</span
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                >) you will not access the Site through automated or non-human
                means, whether through a bot, script, or otherwise; (</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >6</span
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                >) you will not use the Site for any illegal or unauthorized
                purpose; and (</span
              ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >7</span
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                >) your use of the Site will not violate any applicable law or
                regulation.</span
              >
            </div>
          </div>
          <div style="text-align: justify; line-height: 1"><br /></div>
          <div style="text-align: justify; line-height: 115%">
            <div
              data-custom-class="body_text"
              style="line-height: 1.5; text-align: left"
            >
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Site (or any portion thereof).</span
              >
            </div>
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1">
            <div
              data-custom-class="heading_1"
              id="userreg"
              style="line-height: 17.25px"
            >
              <strong
                ><span style="line-height: 24.5333px; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >4.</span
                        ></strong
                      ></span
                    >&nbsp;</strong
                  >USER REGISTRATION</span
                ></strong
              >
            </div>
          </div>
          <div style="line-height: 1">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >You may be required to register with the Site. You agree to
                keep your password confidential and will be responsible for all
                use of your account and password. We reserve the right to
                remove, reclaim, or change a username you select if we
                determine, in our sole discretion, that such username is
                inappropriate, obscene, or otherwise objectionable.</span
              >
            </div>
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 150%">
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 115%">
            <div
              data-custom-class="heading_1"
              id="prohibited"
              style="line-height: 17.25px"
            >
              <strong
                ><span style="line-height: 24.5333px; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >5.</span
                        ></strong
                      ></span
                    >&nbsp;</strong
                  >PROHIBITED ACTIVITIES</span
                ></strong
              >
            </div>
          </div>
          <div style="line-height: 1"><br /></div>
          <div style="line-height: 115%">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >You may not access or use the Site for any purpose other than
                that for which we make the Site available. The Site may not be
                used in connection with any commercial endeavors except those
                that are specifically endorsed or approved by us.</span
              >
            </div>
          </div>
          <div style="line-height: 1"><br /></div>
          <div style="line-height: 115%">
            <div style="text-align: justify; line-height: 17.25px">
              <div style="line-height: 17.25px">
                <div
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span
                    style="
                      font-size: 15px;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >As a user of the Site, you agree not to:</span
                  >
                </div>
              </div>
              <ul>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span
                    style="
                      font-size: 15px;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        font-family: sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        text-indent: -29.4px;
                        background-color: rgb(255, 255, 255);
                        color: rgb(89, 89, 89);
                      "
                      >Systematically retrieve data or other content from the
                      Site to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us.</span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Trick, defraud, or mislead us and other users,
                            especially in any attempt to learn sensitive account
                            information such as user passwords.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Circumvent, disable, or otherwise interfere with
                            security-related features of the Site, including
                            features that prevent or restrict the use or copying
                            of any Content or enforce limitations on the use of
                            the Site and/or the Content contained therein.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Disparage, tarnish, or otherwise harm, in our
                            opinion, us and/or the Site.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Use any information obtained from the Site in order
                            to harass, abuse, or harm another person.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Make improper use of our support services or submit
                            false reports of abuse or misconduct.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Use the Site in a manner inconsistent with any
                            applicable laws or regulations.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Engage in unauthorized framing of or linking to the
                            Site.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Upload or transmit (or attempt to upload or to
                            transmit) viruses, Trojan horses, or other material,
                            including excessive use of capital letters and
                            spamming (continuous posting of repetitive text),
                            that interferes with any party&rsquo;s uninterrupted
                            use and enjoyment of the Site or modifies, impairs,
                            disrupts, alters, or interferes with the use,
                            features, functions, operation, or maintenance of
                            the Site.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Engage in any automated use of the system, such as
                            using scripts to send comments or messages, or using
                            any data mining, robots, or similar data gathering
                            and extraction tools.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Delete the copyright or other proprietary rights
                            notice from any Content.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Attempt to impersonate another user or person or
                            use the username of another user.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Upload or transmit (or attempt to upload or to
                            transmit) any material that acts as a passive or
                            active information collection or transmission
                            mechanism, including without limitation, clear
                            graphics interchange formats (&ldquo;gifs&rdquo;),
                            1&times;1 pixels, web bugs, cookies, or other
                            similar devices (sometimes referred to as
                            &ldquo;spyware&rdquo; or &ldquo;passive collection
                            mechanisms&rdquo; or &ldquo;pcms&rdquo;).</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Interfere with, disrupt, or create an undue burden
                            on the Site or the networks or services connected to
                            the Site.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Harass, annoy, intimidate, or threaten any of our
                            employees or agents engaged in providing any portion
                            of the Site to you.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Attempt to bypass any measures of the Site designed
                            to prevent or restrict access to the Site, or any
                            portion of the Site.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Copy or adapt the Site&rsquo;s software, including
                            but not limited to Flash, PHP, HTML, JavaScript, or
                            other code.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Except as permitted by applicable law, decipher,
                            decompile, disassemble, or reverse engineer any of
                            the software comprising or in any way making up a
                            part of the Site.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Except as may be the result of standard search
                            engine or Internet browser usage, use, launch,
                            develop, or distribute any automated system,
                            including without limitation, any spider, robot,
                            cheat utility, scraper, or offline reader that
                            accesses the Site, or using or launching any
                            unauthorized script or other software.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Use a buying agent or purchasing agent to make
                            purchases on the Site.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Make any unauthorized use of the Site, including
                            collecting usernames and/or email addresses of users
                            by electronic or other means for the purpose of
                            sending unsolicited email, or creating user accounts
                            by automated means or under false pretenses.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Use the Site as part of any effort to compete with
                            us or otherwise use the Site and/or the Content for
                            any revenue-generating endeavor or commercial
                            enterprise.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 15px"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                      ><span
                        style="
                          font-family: sans-serif;
                          font-style: normal;
                          font-weight: 400;
                          text-indent: -29.4px;
                          background-color: rgb(255, 255, 255);
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                          ><span
                            style="
                              font-family: sans-serif;
                              font-style: normal;
                              font-weight: 400;
                              text-indent: -29.4px;
                              background-color: rgb(255, 255, 255);
                              color: rgb(89, 89, 89);
                            "
                            >Sell or otherwise transfer your profile.</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
              </ul>
            </div>
            <div style="text-align: justify; line-height: 1.5"><br /></div>
            <div style="text-align: justify; line-height: 1.5"><br /></div>
            <div style="text-align: justify; line-height: 1">
              <div
                data-custom-class="heading_1"
                id="ugc"
                style="line-height: 17.25px; text-align: left"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >6.</span
                          ></strong
                        ></span
                      >&nbsp;</strong
                    >USER GENERATED CONTRIBUTIONS</span
                  ></strong
                >
              </div>
            </div>
            <div style="text-align: justify; line-height: 1"><br /></div>
            <div style="text-align: justify; line-height: 1">
              <div
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >The Site does not offer users to submit or post content. We
                  may provide you with the opportunity to create, submit, post,
                  display, transmit, perform, publish, distribute, or broadcast
                  content and materials to us or on the Site, including but not
                  limited to text, writings, video, audio, photographs,
                  graphics, comments, suggestions, or personal information or
                  other material (collectively, &quot;Contributions&quot;).
                  Contributions may be viewable by other users of the Site and
                  through third-party websites. As such, any Contributions you
                  transmit may be treated in accordance with the Site Privacy
                  Policy. When you create or make available any Contributions,
                  you thereby represent and warrant that:</span
                >
              </div>
              <ul>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >The creation,
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >distribution, transmission, public display, or
                      performance, and the accessing, downloading, or copying of
                      your Contributions do not and will not infringe the
                      proprietary rights, including but not limited to the
                      copyright, patent, trademark, trade secret, or moral
                      rights of any third party.</span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >You are the creator and owner of or have the necessary
                      licenses, rights, consents, releases, and permissions to
                      use and to authorize us, the Site, and other users of the
                      Site to use your Contributions in any manner contemplated
                      by the Site and these Terms of Use.</span
                    ></span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >You have the written consent, release, and/or permission of
                    each and every identifiable individual person in your
                    Contributions to use the name or likeness of each and every
                    such identifiable individual person to enable inclusion and
                    use of your Contributions in any manner contemplated by the
                    Site and these Terms of Use.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions are not false, inaccurate, or
                    misleading.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions do not violate any applicable law,
                    regulation, or rule.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions do not violate the privacy or publicity
                    rights of any third party.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions do not violate any applicable law
                    concerning child pornography, or otherwise intended to
                    protect the health or well-being of minors.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions do not include any offensive comments
                    that are connected to race, national origin, gender, sexual
                    preference, or physical handicap.</span
                  >
                </li>
                <li
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span style="font-size: 14px; color: rgb(89, 89, 89)"
                    >Your Contributions do not otherwise violate, or link to
                    material that violates, any provision of these Terms of Use,
                    or any applicable law or regulation.</span
                  >
                </li>
              </ul>
              <div
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >Any use of the Site in violation of the foregoing violates
                  these Terms of Use and may result in, among other things,
                  termination or suspension of your rights to use the
                  Site.</span
                >
              </div>
            </div>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 115%">
            <div style="text-align: justify; line-height: 1">
              <div
                data-custom-class="heading_1"
                id="license"
                style="line-height: 1.5; text-align: left"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >7.</span
                          ></strong
                        ></span
                      >&nbsp;</strong
                    >CONTRIBUTION LICENSE</span
                  ></strong
                >
              </div>
              <div style="line-height: 17.25px; text-align: left"><br /></div>
              <div style="text-align: justify; line-height: 1"><br /></div>
            </div>
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >You and the Site agree that we may access, store, process, and
                use any information and personal data that you provide following
                the terms of the Privacy Policy and your choices (including
                settings).</span
              >
            </div>
            <div style="line-height: 1"><br /></div>
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >By submitting suggestions or other feedback regarding the Site,
                you agree that we can use and share such feedback for any
                purpose without compensation to you.</span
              >
            </div>
            <div style="line-height: 1"><br /></div>
            <div
              data-custom-class="body_text"
              style="line-height: 1.5; text-align: left"
            >
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >We do not assert any ownership over your Contributions. You
                retain full ownership of all of your Contributions and any
                intellectual property rights or other proprietary rights
                associated with your Contributions. We are not liable for any
                statements or representations in your Contributions provided by
                you in any area on the Site. You are solely responsible for your
                Contributions to the Site and you expressly agree to exonerate
                us from any and all responsibility and to refrain from any legal
                action against us regarding your Contributions.</span
              >
            </div>
            <div style="line-height: 1.5"><br /></div>
            <div style="line-height: 1.5"><br /></div>
            <div style="line-height: 1.5"><br /></div>
            <div style="line-height: 1"><br /></div>
          </div>
        </div>
        <div align="center"><br /></div>
        <div
          data-custom-class="heading_1"
          id="submissions"
          style="line-height: 115%"
        >
          <strong
            ><span
              style="line-height: 115%; font-family: Arial; font-size: 19px"
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      >8.</span
                    ></strong
                  ></span
                >&nbsp;</strong
              >SUBMISSIONS</span
            ></strong
          >
        </div>
        <div style="line-height: 1"><br /></div>
        <div data-custom-class="body_text" style="line-height: 1.5">
          <span
            style="
              font-size: 15px;
              line-height: 115%;
              font-family: Arial;
              color: #595959;
            "
            ><span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, or other information regarding the
              Site (&quot;Submissions&quot;) provided by you to us are
              non-confidential and shall become our sole property. We shall own
              exclusive rights, including all intellectual property rights, and
              shall be entitled to the unrestricted use and dissemination of
              these Submissions for any lawful purpose, commercial or otherwise,
              without acknowledgment or compensation to you. You hereby waive
              all moral rights to any such Submissions, and you hereby warrant
              that any such Submissions are original with you or that you have
              the right to submit such Submissions. You agree there shall be no
              recourse against us for any alleged or actual infringement or
              misappropriation of any proprietary right in your
              Submissions.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5"><br /></div>
        <div align="center"><br /></div>
        <div
          data-custom-class="heading_1"
          id="thirdparty"
          style="line-height: 115%"
        >
          <strong
            ><span
              style="line-height: 115%; font-family: Arial; font-size: 19px"
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      >9.</span
                    ></strong
                  ></span
                >&nbsp;</strong
              >THIRD-PARTY WEBSITE AND CONTENT</span
            ></strong
          >
        </div>
        <div style="line-height: 1"><br /></div>
        <div data-custom-class="body_text" style="line-height: 1.5">
          <span
            style="
              font-size: 15px;
              line-height: 115%;
              font-family: Arial;
              color: #595959;
            "
            >The Site may contain (or you may be sent via the Site) links to
            other websites (&quot;Third-Party Websites&quot;) as well as
            articles, photographs, text, graphics, pictures, designs, music,
            sound, video, information, applications, software, and other content
            or items belonging to or originating from third parties
            (&quot;Third-Party Content&quot;). Such Third-Party Websites and
            Third-Party Content are not investigated, monitored, or checked for
            accuracy, appropriateness, or completeness by us, and we are not
            responsible for any Third-Party Websites accessed through the Site
            or any Third-Party Content posted on, available through, or
            installed from the Site, including the content, accuracy,
            offensiveness, opinions, reliability, privacy practices, or other
            policies of or contained in the Third-Party Websites or the
            Third-Party Content. Inclusion of, linking to, or permitting the use
            or installation of any Third-Party Websites or any Third-Party
            Content does not imply approval or endorsement thereof by us. If you
            decide to leave the Site and access the Third-Party Websites or to
            use or install any Third-Party Content, you do so at your own risk,
            and you should be aware these Terms of Use no longer govern. You
            should review the applicable terms and policies, including privacy
            and data gathering practices, of any website to which you navigate
            from the Site or relating to any applications you use or install
            from the Site. Any purchases you make through Third-Party Websites
            will be through other websites and from other companies, and we take
            no responsibility whatsoever in relation to such purchases which are
            exclusively between you and the applicable third party. You agree
            and acknowledge that we do not endorse the products or services
            offered on Third-Party Websites and you shall hold us harmless from
            any harm caused by your purchase of such products or services.
            Additionally, you shall hold us harmless from any losses sustained
            by you or harm caused to you relating to or resulting in any way
            from any Third-Party Content or any contact with Third-Party
            Websites.</span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5"><br /></div>
        <div align="center"><br /></div>
        <div align="center" style="text-align: left; line-height: 1">
          <div
            data-custom-class="heading_1"
            id="advertisers"
            style="line-height: 115%"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >10.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >ADVERTISERS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >We allow advertisers to display their advertisements and other
              information in certain areas of the Site, such as sidebar
              advertisements or banner advertisements. If you are an advertiser,
              you shall take full responsibility for any advertisements you
              place on the Site and any services provided on the Site or
              products sold through those advertisements. Further, as an
              advertiser, you warrant and represent that you possess all rights
              and authority to place advertisements on the Site, including, but
              not limited to, intellectual property rights, publicity rights,
              and contractual rights.&nbsp;</span
            >We simply provide the space to place such advertisements, and we
            have no other relationship with advertisers.
          </div>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5"><br /></div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <div
            data-custom-class="heading_1"
            id="sitemanage"
            style="line-height: 115%"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >11.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >SITE MANAGEMENT</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >We reserve the right, but not the obligation, to: (1) monitor the
              Site for violations of these Terms of Use; (2) take appropriate
              legal action against anyone who, in our sole discretion, violates
              the law or these Terms of Use, including without limitation,
              reporting such user to law enforcement authorities; (3) in our
              sole discretion and without limitation, refuse, restrict access
              to, limit the availability of, or disable (to the extent
              technologically feasible) any of your Contributions or any portion
              thereof; (4) in our sole discretion and without limitation,
              notice, or liability, to remove from the Site or otherwise disable
              all files and content that are excessive in size or are in any way
              burdensome to our systems; and (5) otherwise manage the Site in a
              manner designed to protect our rights and property and to
              facilitate the proper functioning of the Site.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="privacypolicy1"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >12.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >PRIVACY POLICY</span
              ></strong
            >
          </div>
          <div style="line-height: 1"><br /></div>
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >We care about data privacy and security.&nbsp;</span
            ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
              >Please review our Privacy Policy:</span
            ><b style="color: rgb(89, 89, 89); font-size: 14.6667px"
              >&nbsp;<a
                data-custom-class="link"
                href="https://nutshellpro.com/page/privacy"
                target="_blank"
                >https://nutshellpro.com/page/privacy</a
              ></b
            ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
              >.&nbsp;</span
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              >By using the Site, you agree to be bound by our Privacy Policy,
              which is incorporated into these Terms of Use. Please be advised
              the Site is hosted in
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >the Netherlands</span
              >. If you access the Site from any other region of the world with
              laws or other requirements governing personal data collection,
              use, or disclosure that differ from applicable laws in
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >the Netherlands</span
              >, then through your continued use of the Site, you are
              transferring your data to
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >the Netherlands</span
              >, and you agree to have your data transferred to and processed in
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >the Netherlands</span
              >.&nbsp;</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <div style="text-align: justify; line-height: 1.5"><br /></div>
          <div align="center" style="text-align: left">
            <div
              data-custom-class="heading_1"
              id="terms"
              style="line-height: 115%"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >13.</span
                        ></strong
                      ></span
                    >&nbsp;</strong
                  >TERM AND TERMINATION</span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 115%;
                  font-family: Arial;
                  color: rgb(89, 89, 89);
                "
                >These Terms of Use shall remain in full force and effect while
                you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
                TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION
                AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
                SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
                FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION
                FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
                CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
                SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT
                YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                DISCRETION.</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 115%;
                  font-family: Arial;
                  color: #595959;
                "
                >If we terminate or suspend your account for any reason, you are
                prohibited from registering and creating a new account under
                your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party.
                In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including
                without limitation pursuing civil, criminal, and injunctive
                redress.</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div style="line-height: 1.5"><br /></div>
            <div
              data-custom-class="heading_1"
              id="modifications"
              style="line-height: 1.5"
            >
              <strong
                ><span style="line-height: 115%; font-family: Arial"
                  ><span style="font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >14.</span
                          ></strong
                        ></span
                      >&nbsp;</strong
                    >MODIFICATIONS AND INTERRUPTIONS</span
                  ></span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 115%;
                  font-family: Arial;
                  color: #595959;
                "
                >We reserve the right to change, modify, or remove the contents
                of the Site at any time or for any reason at our sole discretion
                without notice. However, we have no obligation to update any
                information on our Site. We also reserve the right to modify or
                discontinue all or part of the Site without notice at any time.
                We will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Site.</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 115%;
                  font-family: Arial;
                  color: #595959;
                "
                >We cannot guarantee the Site will be available at all times. We
                may experience hardware, software, or other problems or need to
                perform maintenance related to the Site, resulting in
                interruptions, delays, or errors. We reserve the right to
                change, revise, update, suspend, discontinue, or otherwise
                modify the Site at any time or for any reason without notice to
                you. You agree that we have no liability whatsoever for any
                loss, damage, or inconvenience caused by your inability to
                access or use the Site during any downtime or discontinuance of
                the Site. Nothing in these Terms of Use will be construed to
                obligate us to maintain and support the Site or to supply any
                corrections, updates, or releases in connection therewith.</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div style="line-height: 1.5"><br /></div>
            <div
              data-custom-class="heading_1"
              id="law"
              style="line-height: 1.5"
            >
              <strong
                ><span style="line-height: 115%; font-family: Arial"
                  ><span style="font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >15.</span
                          ></strong
                        ></span
                      >&nbsp;</strong
                    >GOVERNING LAW</span
                  >&nbsp;</span
                ></strong
              >
            </div>
            <div style="line-height: 1"><br /></div>
            <div style="line-height: 115%"><br /></div>
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >These conditions are governed by and interpreted following
                  the laws of the Netherlands,</span
                ></span
              >
              and the use of the United Nations Convention of Contracts for the
              International Sale of Goods is expressly excluded. If your
              habitual residence is in the EU, and you are a consumer, you
              additionally possess the protection provided to you by obligatory
              provisions of the law of your country of residence. NutshellPro
              and yourself both agree to submit to the non-exclusive
              jurisdiction of the courts of Netherlands, which means that you
              may make a claim to defend your consumer protection rights in
              regards to these Conditions of Use in
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                ><span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >the Netherlands</span
                ></span
              >, or in the EU country in which you reside.
            </div>
            <div style="line-height: 1.5"><br /></div>
            <div style="line-height: 1.5"><br /></div>
            <div
              data-custom-class="heading_1"
              id="disputes"
              style="line-height: 1.5"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >16.</span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    >&nbsp;</strong
                  >DISPUTE RESOLUTION</span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div style="line-height: 115%">
              <div
                data-custom-class="heading_2"
                style="line-height: 17.25px; text-align: left"
              >
                <strong>Informal Negotiations</strong>
              </div>
            </div>
            <div style="line-height: 1"><br /></div>
            <div style="line-height: 1">
              <div
                data-custom-class="body_text"
                style="text-align: left; line-height: 1.5"
              >
                <span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >To expedite resolution and control the cost of any dispute,
                  controversy, or claim related to these Terms of Use (each
                  &quot;Dispute&quot; and collectively, the
                  &ldquo;Disputes&rdquo;) brought by either you or us
                  (individually, a &ldquo;Party&rdquo; and collectively, the
                  &ldquo;Parties&rdquo;), the Parties agree to first attempt to
                  negotiate any Dispute (except those Disputes expressly
                  provided below) informally for at least ninety (90) days
                  before initiating arbitration. Such informal negotiations
                  commence upon written notice from one Party to the other
                  Party.</span
                >
              </div>
            </div>
            <div style="line-height: 1"><br /></div>
            <div style="line-height: 1">
              <div
                data-custom-class="heading_2"
                style="line-height: 17.25px; text-align: left"
              >
                <strong>Binding Arbitration</strong>
              </div>
            </div>
            <div style="line-height: 1"><br /></div>
            <div style="line-height: 1"><br /></div>
          </div>
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 16.8667px;
                color: rgb(89, 89, 89);
              "
              >Any dispute
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >arising from the relationships between the Parties to this
                contract shall be determined by one arbitrator who will be
                chosen in accordance with the Arbitration and Internal Rules of
                the European Court of Arbitration being part of the European
                Centre of Arbitration having its seat in Strasbourg, and which
                are in force at the time the application for arbitration is
                filed, and of which adoption of this clause constitutes
                acceptance. The seat of arbitration shall be Netherlands.<span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >&nbsp;The language of the proceedings shall be English.</span
                >
                Applicable rules of substantive law shall be the law of the
                <span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    style="
                      font-size: 15px;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >Netherlands</span
                  ></span
                >.</span
              ></span
            >
          </div>
          <div style="text-align: justify; line-height: 1"><br /></div>
          <div style="text-align: justify; line-height: 1">
            <div
              data-custom-class="heading_2"
              style="line-height: 17.25px; text-align: left"
            >
              <strong>Restrictions</strong>
            </div>
          </div>
          <div style="text-align: justify; line-height: 1"><br /></div>
          <div style="text-align: justify; line-height: 1">
            <div
              data-custom-class="body_text"
              style="text-align: left; line-height: 1.5"
            >
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >The Parties agree that any arbitration shall be limited to the
                Dispute between the Parties individually. To the full extent
                permitted by law, (a) no arbitration shall be joined with any
                other proceeding; (b) there is no right or authority for any
                Dispute to be arbitrated on a class-action basis or to utilize
                class action procedures; and (c) there is no right or authority
                for any Dispute to be brought in a purported representative
                capacity on behalf of the general public or any other
                persons.</span
              >
            </div>
          </div>
          <div style="text-align: justify; line-height: 1"><br /></div>
          <div style="text-align: justify; line-height: 1">
            <div
              data-custom-class="heading_2"
              style="line-height: 17.25px; text-align: left"
            >
              <strong
                >Exceptions to Informal Negotiations and Arbitration</strong
              >
            </div>
          </div>
          <div style="text-align: justify; line-height: 1"><br /></div>
          <div style="text-align: justify; line-height: 1">
            <div
              data-custom-class="body_text"
              style="text-align: left; line-height: 1.5"
            >
              <span
                style="
                  font-size: 15px;
                  line-height: 16.8667px;
                  color: rgb(89, 89, 89);
                "
                >The Parties agree that the following Disputes are not subject
                to the above provisions concerning informal negotiations and
                binding arbitration: (a) any Disputes seeking to enforce or
                protect, or concerning the validity of, any of the intellectual
                property rights of a Party; (b) any Dispute related to, or
                arising from, allegations of theft, piracy, invasion of privacy,
                or unauthorized use; and (c) any claim for injunctive relief. If
                this provision is found to be illegal or unenforceable, then
                neither Party will elect to arbitrate any Dispute falling within
                that portion of this provision found to be illegal or
                unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction
                above, and the Parties agree to submit to the personal
                jurisdiction of that court.</span
              >
            </div>
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="corrections"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >17.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >CORRECTIONS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >There may be information on the Site that contains typographical
              errors, inaccuracies, or omissions, including descriptions,
              pricing, availability, and various other information. We reserve
              the right to correct any errors, inaccuracies, or omissions and to
              change or update the information on the Site at any time, without
              prior notice.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="disclaimer"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >18.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >DISCLAIMER</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
              AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR
              SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
              WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
              YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
              ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR
              THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME
              NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
              PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
              USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
              AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
              OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS,
              VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
              THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
              OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
              TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
              WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
              PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
              THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
              APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE
              WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
              ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
              PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
              THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
              JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="liability"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >19.&nbsp;</span
                      ></strong
                    ></span
                  ></strong
                >LIMITATIONS OF LIABILITY</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
              ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU
              FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION,
              WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
              US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
              OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
              ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
              HAVE ADDITIONAL RIGHTS.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="indemnification"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >20.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >INDEMNIFICATION</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys&rsquo; fees and expenses, made by any third party due to
              or arising out of: (<span style="font-size: 14.6667px">1</span>)
              use of the Site; (<span style="font-size: 14.6667px">2</span>)
              breach of these Terms of Use; (<span style="font-size: 14.6667px"
                >3</span
              >) any breach of your representations and warranties set forth in
              these Terms of Use; (<span style="font-size: 14.6667px">4</span>)
              your violation of the rights of a third party, including but not
              limited to intellectual property rights; or (<span
                style="font-size: 14.6667px"
                >5</span
              >) any overt harmful act toward any other user of the Site with
              whom you connected via the Site. Notwithstanding the foregoing, we
              reserve the right, at your expense, to assume the exclusive
              defense and control of any matter for which you are required to
              indemnify us, and you agree to cooperate, at your expense, with
              our defense of such claims. We will use reasonable efforts to
              notify you of any such claim, action, or proceeding which is
              subject to this indemnification upon becoming aware of it.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="userdata"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >21.</span
                          ></strong
                        ></span
                      ></strong
                    >&nbsp;</span
                  ></strong
                >USER DATA</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >We will maintain certain data that you transmit to the Site for
              the purpose of managing the performance of the Site, as well as
              data relating to your use of the Site. Although we perform regular
              routine backups of data, you are solely responsible for all data
              that you transmit or that relates to any activity you have
              undertaken using the Site. You agree that we shall have no
              liability to you for any loss or corruption of any such data, and
              you hereby waive any right of action against us arising from any
              such loss or corruption of such data.</span
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1.5">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="electronic"
            style="line-height: 1.5"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >22.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >Visiting the Site, sending us emails, and completing online forms
              constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SITE. You hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic
              means.</span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="california"
            style="line-height: 115%"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >23.&nbsp;</span
                      ></strong
                    ></span
                  ></strong
                >CALIFORNIA USERS AND RESIDENTS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.</span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div
            data-custom-class="heading_1"
            id="misc"
            style="line-height: 115%"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="
                      line-height: 115%;
                      font-family: Arial;
                      font-size: 19px;
                    "
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >24.</span
                      ></strong
                    ></span
                  >&nbsp;</strong
                >MISCELLANEOUS</span
              ></strong
            >
          </div>
        </div>
        <div align="center" style="text-align: left; line-height: 1">
          <br />
        </div>
        <div align="center" style="text-align: left">
          <div data-custom-class="body_text" style="line-height: 1.5">
            <span
              style="
                font-size: 15px;
                line-height: 115%;
                font-family: Arial;
                color: #595959;
              "
              >These Terms of Use and any policies or operating rules posted by
              us on the Site or in respect to the Site constitute the entire
              agreement and understanding between you and us. Our failure to
              exercise or enforce any right or provision of these Terms of Use
              shall not operate as a waiver of such right or provision. These
              Terms of Use operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Terms of Use is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Terms of Use and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Terms of Use or use of the
              Site. You agree that these Terms of Use will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all defenses you may have based on the electronic form of
              these Terms of Use and the lack of signing by the parties hereto
              to execute these Terms of Use.</span
            >
          </div>
          <div style="line-height: 1"><br /></div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div style="line-height: 1.5"><br /></div>
            <div
              data-custom-class="heading_1"
              id="contact"
              style="line-height: 1.5"
            >
              <strong
                ><span style="line-height: 115%; font-family: Arial"
                  ><span style="font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >25.</span
                          ></strong
                        ></span
                      >&nbsp;</strong
                    >CONTACT US</span
                  ></span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 115%;
                  font-family: Arial;
                  color: #595959;
                "
                >If you have any questions or concerns regarding these Terms,
                please contact us at:
              </span>
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left">
            <div data-custom-class="body_text" style="line-height: 1.5">
              <span
                style="
                  font-size: 15px;
                  line-height: 115%;
                  font-family: Arial;
                  color: rgb(89, 89, 89);
                "
              >
                Four Winds Software<br />
                181 Sundown Rd<br />
                Grahamsville NY 12740, USA<br />
                hello@nutshellpro.com
              </span>
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 150%">
            <div style="line-height: 1.5"><br /></div>
            <div style="line-height: 1">
              <div
                data-custom-class="heading_1"
                id="userreg"
                style="line-height: 17.25px"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >26.</span
                          ></strong
                        ></span
                      >&nbsp;</strong
                    >REFUND</span
                  ></strong
                >
              </div>
            </div>
            <div style="line-height: 1">
              <div data-custom-class="body_text" style="line-height: 1.5">
                <span
                  style="
                    font-size: 15px;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                >
                  All purchases are refundable within 14 days of purchase. If
                  you are not satisfied with the product, please contact us.
                </span>
              </div>
            </div>
          </div>
          <strong><strong>&nbsp;</strong></strong>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1em;
}
p {
  margin-bottom: 1em;
}
ul {
  margin-left: 1em;
}
li {
  margin-bottom: 0.5em;
  list-style-type: disc;
}
</style>
