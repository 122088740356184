<template>
  <div>
    <Navbar />
    <main class="isolate pt-14">
      <router-view />
    </main>
    <MyFooter />
  </div>
</template>
<script setup>
import { useHead } from "@unhead/vue";

import MyFooter from "@/components/Footers/Footer.vue";
import Navbar from "@/components/Navbars/Navbar.vue";
import { TITLE } from "@/constants";

useHead({
  title: TITLE,
});
</script>
