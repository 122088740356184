<template>
  <dl
    v-if="historyStore.userSummaries?.summaries?.length > 0"
    class="space-y-2"
  >
    <dt class="text-lg font-semibold leading-7 text-gray-900">
      Your recent summaries
      <router-link
        :to="`/history`"
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-brand-100 text-brand-800"
      >
        View all
      </router-link>
    </dt>
    <span v-if="authStore.user?.summary_duration_property" class="text-sm">
      {{ formatDurationUsage(authStore.user?.summary_duration_property) }}
      saved so far
    </span>
    <dd
      v-for="summary in historyStore.userSummaries.summaries"
      :key="summary.summary_slug"
      class="truncate flex items-center"
    >
      <router-link :to="`/s/${summary.summary_slug}`" class="text-brand-700">
        <span v-html="`- ${summary.video_title}`"></span>
      </router-link>
      <span class="text-xs ml-1"> ({{ summary.website }}) </span>
    </dd>
  </dl>
  <dl v-else>
    <dt class="text-lg font-semibold leading-7 text-gray-900 items-start">
      Try NutshellPro with:
    </dt>
    <SummarizerExplore />
  </dl>
</template>
<script setup>
import { onMounted } from "vue";
import { useSummarizerHistoryStore } from "@/stores/summarizer/summarizerHistoryStore";

import { formatDurationUsage } from "@/utils";
import { useAuthStore } from "@/stores/authStore";

import SummarizerExplore from "@/components/Summarizer/SummarizerExplore.vue";

const authStore = useAuthStore();

const historyStore = useSummarizerHistoryStore();

onMounted(() => {
  historyStore.fetchUserSummaries();
});
</script>
