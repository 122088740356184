import { ref } from "vue";
import { defineStore } from "pinia";

export const useModalStore = defineStore("modal", () => {
  const showModal = ref(false);
  const showIcon = ref(false);
  const showTextField = ref(false);
  const showSubmitButton = ref(false);
  const showCloseButton = ref(true);

  const modalTitle = ref("");
  const modalText = ref("");
  const submitButtonText = ref("Submit");
  const actionButton = ref(null);
  const actionFunc = ref(null);

  const setShowModal = (show) => {
    showModal.value = show;
  };

  const setShowIcon = (show) => {
    showIcon.value = show;
  };

  const setModalTitle = (title) => {
    modalTitle.value = title;
  };

  const setModalText = (text) => {
    modalText.value = text;
  };

  const displayModal = ({
    title,
    text,
    buttonText = "Submit",
    textField = false,
    submitButton = false,
    closeButton = true,
    action = null,
    icon = false,
  }) => {
    showModal.value = true;
    modalTitle.value = title;
    modalText.value = text;
    submitButtonText.value = buttonText;
    showIcon.value = icon;
    showTextField.value = textField;
    showSubmitButton.value = submitButton;
    showCloseButton.value = closeButton;
    actionFunc.value = action;
  };
  const closeModal = () => {
    showModal.value = false;
    setTimeout(() => {
      showIcon.value = false;
      showTextField.value = false;
      showSubmitButton.value = false;
      showCloseButton.value = true;
      actionButton.value = null;
      actionFunc.value = null;
      modalTitle.value = "";
      modalText.value = "";
      submitButtonText.value = "Submit";
    }, 300); // same time as the fade out transition
  };

  return {
    showModal,
    showIcon,
    showTextField,
    showSubmitButton,
    showCloseButton,
    modalTitle,
    modalText,
    submitButtonText,
    actionButton,
    actionFunc,
    setShowModal,
    setShowIcon,
    setModalTitle,
    setModalText,
    displayModal,
    closeModal,
  };
});
