import { defineStore } from "pinia";

export const useAlertStore = defineStore("alert", {
  state: () => ({
    message: "",
    type: "",
    visible: false,
  }),
  actions: {
    showSuccess(message, duration = 5000) {
      this.message = message;
      this.type = "success";
      this.visible = true;
      if (duration) {
        setTimeout(() => {
          this.hide();
        }, duration);
      }
    },
    showError(message, duration = 5000) {
      this.message = message;
      this.type = "error";
      this.visible = true;
      if (duration) {
        setTimeout(() => {
          this.hide();
        }, duration);
      }
    },
    hide() {
      this.visible = false;
    },
  },
});
