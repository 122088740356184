<template>
  <div>
    <form
      @submit.prevent="resultsStore.handleURLSubmit"
      class="mt-6 sm:flex sm:items-center"
    >
      <div class="w-full relative rounded-md shadow-sm">
        <label for="videoUrl" class="sr-only">Web URL</label>
        <input
          v-model="videoUrl"
          @keydown.enter.prevent="resultsStore.handleURLSubmit"
          required
          type="text"
          ref="videoUrlRef"
          name="videoUrls"
          id="video-urls"
          placeholder="https://youtube.com/watch?v=AnYVidEo"
          class="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
        />
        <div
          @click="videoUrl = null"
          class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <XMarkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
        <button
          type="submit"
          class="block w-full rounded-md bg-brand-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
          :disabled="statusStore.isResultsLoading"
        >
          <SpinnerIcon v-if="statusStore.isResultsLoading" />
          <BoltIcon v-else class="h-5 w-5 inline" aria-hidden="true" />
          Summarize
        </button>
      </div>
    </form>

    <FormAlert
      v-if="statusStore.connectionStatusMessage"
      :message="statusStore.connectionStatusMessage"
      :action="statusStore.connectionErrorAction"
      :type="statusStore.connectionStatusType"
    />

    <div
      v-if="!statusStore.isResultsLoading"
      class="mt-2 flex items-center justify-center w-full"
    >
      <label
        for="dropzone-file"
        class="flex flex-col items-center justify-center w-full border border-linen-300 border-dashed rounded-lg cursor-pointer bg-linen-50 hover:bg-linen-100"
      >
        <div class="flex flex-col items-center justify-center pt-5 pb-6 px-1">
          <p class="mb-2 text-center text-sm text-gray-900">
            or <span class="font-semibold">upload a file</span>
          </p>
          <p class="text-sm text-center text-gray-900">
            MP4, MOV, MP3, WAV up to 100MB
          </p>
          <p class="text-xs text-center text-gray-900">
            File will be deleted when summarization is finished
          </p>
        </div>
        <input
          v-if="authStore.user.is_authenticated"
          @click="formStore.getUploadUrl"
          @change="formStore.handleFileUpload"
          id="dropzone-file"
          type="file"
          accept="audio/*,video/*"
          class="hidden"
        />
        <input
          v-else
          @click="handleGuestClick"
          id="dropzone-file"
          class="hidden"
        />
      </label>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useAlertStore } from "@/stores/alertStore";
import { useSummarizerFormStore } from "@/stores/summarizer/summarizerFormStore";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useSummarizerStatusStore } from "@/stores/summarizer/summarizerStatusStore";
import { BoltIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import SpinnerIcon from "@/components/Icons/SpinnerIcon.vue";
import FormAlert from "@/components/Alerts/FormAlert.vue";

const videoUrlRef = ref(null);

const router = useRouter();
const authStore = useAuthStore();
const alertStore = useAlertStore();
const formStore = useSummarizerFormStore();
const resultsStore = useSummarizerResultsStore();
const statusStore = useSummarizerStatusStore();

const { videoUrl } = storeToRefs(formStore);

const handleGuestClick = () => {
  alertStore.showError("Please login to upload a file.");
  router.push({ name: "Login" });
};

onMounted(() => {
  videoUrlRef.value.focus();
});
</script>
