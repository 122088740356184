import { createApp } from "vue";
import { createPinia } from "pinia";
import { createWebHistory, createRouter } from "vue-router";
import { createHead } from "@unhead/vue";
import { markRaw } from "vue";
import axios from "axios";
import SimpleAnalytics from "simple-analytics-vue";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app
import App from "@/App.vue";

// layouts
import Auth from "@/layouts/Auth.vue";
import Page from "@/layouts/Page.vue";

// views for Auth layout
import Login from "@/views/auth/Login.vue";
import LoginEmail from "@/views/auth/LoginEmail.vue";
import RegisterEmail from "@/views/auth/RegisterEmail.vue";
import Logout from "@/views/auth/Logout.vue";
import VerifyToken from "@/views/auth/VerifyToken.vue";
import GoogleCallback from "@/views/auth/GoogleCallback.vue";

// views for Summarization layout
import SummarizationHome from "@/views/summarization/SummarizationHome.vue";
import SummarizationItem from "@/views/summarization/SummarizationItem.vue";

// views for Summarization History layout
import SummarizationHistory from "@/views/SummarizationHistory.vue";

// views for Page layout
import Privacy from "@/views/page/Privacy.vue";
import Terms from "@/views/page/Terms.vue";

// views without layouts
import Index from "@/views/Index.vue";
import Profile from "@/views/Profile.vue";
import Admin from "@/views/Admin.vue";

// views for Business layout
import BusinessLandingPage from "@/views/business/BusinessLandingPage.vue";
import BusinessPanel from "@/views/business/BusinessPanel.vue";

// error views
import NotFound from "@/components/errors/NotFound.vue";

// routes
const routes = [
  {
    path: "/auth",
    name: "Auth",
    redirect: "/auth/login",
    component: Auth,
    props: true,
    children: [
      {
        path: "/auth/login",
        name: "Login",
        component: Login,
        props: true,
      },
      {
        path: "/auth/logout",
        name: "Logout",
        component: Logout,
      },
      {
        path: "/auth/verify-token",
        name: "VerifyToken",
        component: VerifyToken,
      },
      {
        path: "/auth/google/callback",
        name: "GoogleCallback",
        component: GoogleCallback,
      },
      {
        path: "/auth/form/login",
        name: "LoginEmail",
        component: LoginEmail,
        props: true,
      },
      {
        path: "/auth/form/register",
        name: "RegisterEmail",
        component: RegisterEmail,
        props: true,
      },
    ],
  },
  {
    path: "/s",
    name: "SummarizationHome",
    component: SummarizationHome,
  },
  {
    path: "/s/:url",
    name: "SummarizationItem",
    component: SummarizationItem,
    props: (route) => ({ summarySlug: route.params.url }),
  },
  {
    path: "/history",
    name: "SummarizationHistory",
    component: SummarizationHistory,
    props: (route) => ({
      page: route.query.page,
      limit: route.query.limit,
    }),
  },
  {
    path: "/page",
    name: "Page",
    redirect: "/page/terms",
    component: Page,
    props: true,
    children: [
      {
        path: "/page/privacy",
        name: "Privacy",
        component: Privacy,
      },
      {
        path: "/page/terms",
        name: "Terms",
        component: Terms,
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    props: true,
    component: Index,
  },
  {
    path: "/user/account",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/user/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: async (to, from, next) => {
      try {
        const response = await axios.get("/api/auth/users/me");
        if (response.data["is_superuser"]) {
          next();
        } else {
          next({ name: "NotFound" });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
  },
  {
    path: "/business-lknf1itrtwtwcjzwg",
    name: "Business",
    component: BusinessLandingPage,
  },
  {
    path: "/panel-lknf1itrtwtwcjzwg",
    name: "Panel",
    component: BusinessPanel,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

const pinia = createPinia();

pinia.use(({ store }) => {
  store.router = markRaw(router);
});

const head = createHead();

app.mixin({
  methods: {
    limit: (str = "", limit = 0) => str.substring(0, limit),
  },
});

app.use(router);
app.use(pinia);
app.use(head);
app.use(SimpleAnalytics, {
  skip: process.env.VUE_APP_ENVIRONMENT !== "production",
});

app.mount("#app");
