import { ref } from "vue";
import { defineStore } from "pinia";

export const useSummarizerStatusStore = defineStore("summarizerStatus", () => {
  const connectionStatus = ref(null);
  const connectionStatusMessage = ref("");
  const connectionErrorAction = ref("");
  const connectionStatusType = ref(undefined);

  const isResultsLoading = ref(false);
  const isFetchingExistingSummary = ref(true);

  const initializeState = () => {
    connectionStatus.value = false;
    connectionStatusMessage.value = null;
    connectionErrorAction.value = "";
    connectionStatusType.value = null;

    isResultsLoading.value = false;
    isFetchingExistingSummary.value = true;
  };

  const setConnectionStatus = (status) => {
    connectionStatus.value = status;
  };

  const setConnectionStatusMessage = (errorCode) => {
    connectionStatusMessage.value = errorCode;
  };

  const setConnectionStatusType = (statusType) => {
    connectionStatusType.value = statusType;
  };

  const setConnectionErrorAction = (action) => {
    connectionErrorAction.value = action;
  };

  const setIsResultLoading = (isLoading) => {
    isResultsLoading.value = isLoading;
  };

  const setIsFetchingExistingSummary = (isFetching) => {
    isFetchingExistingSummary.value = isFetching;
  };

  return {
    connectionStatus,
    connectionStatusMessage,
    connectionErrorAction,
    connectionStatusType,
    isResultsLoading,
    isFetchingExistingSummary,
    initializeState,
    setConnectionStatus,
    setConnectionStatusMessage,
    setConnectionErrorAction,
    setConnectionStatusType,
    setIsResultLoading,
    setIsFetchingExistingSummary,
  };
});
