import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { useRouter } from "vue-router";
import { useAlertStore } from "./alertStore";

export const useAuthStore = defineStore("auth", () => {
  const alertStore = useAlertStore();
  const router = useRouter();

  const initialUserState = {
    id: null,
    is_authenticated: false,
    remaining_credit: null,
    summary_duration_property: null,
    email: "",
  };

  const authChecked = ref(null);
  const user = ref({ ...initialUserState });

  const setUserRemainingCredit = (remainingCredit) => {
    user.value.remaining_credit = remainingCredit;
  };

  const fetchUser = async (fetchUseroptions = {}) => {
    const { forceLogin = false, returnTo = "" } = fetchUseroptions;
    const options = {
      url: "/api/auth/users/me",
      method: "GET",
    };
    return axios(options)
      .then((response) => {
        user.value = {
          ...response.data,
          is_authenticated: true,
        };
        authChecked.value = true;
      })
      .catch((error) => {
        user.value = { ...initialUserState };
        if (forceLogin) {
          router.push({ name: "Login" });
        }
      });
  };

  function updateUser(userUpdateData) {
    const options = {
      url: "/api/users/me",
      method: "PUT",
      data: userUpdateData,
    };
    return axios(options).then((response) => {
      user.value = {
        ...user.value,
        ...response.data,
      };
    });
  }

  function logoutUser() {
    const options = {
      url: "/api/auth/jwt/logout",
      method: "POST",
    };
    return axios(options)
      .then(() => {
        alertStore.showSuccess("You have successfully logged out.");
        user.value = null;
      })
      .then(() => {
        router.push({ name: "Home" });
      })
      .catch(() => {
        router.push({ name: "Home" });
      });
  }

  async function fetchProfile() {
    const options = {
      url: "/api/users/profile",
      method: "GET",
    };
    return await axios(options)
      .then((response) => {
        user.value = {
          ...user.value,
          ...response.data,
        };
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          alertStore.showError("You have to sign in first.");
          router.push({ name: "Login" });
        }
      });
  }

  // deprecated
  async function cancelSubscription() {
    const alertStore = useAlertStore();
    if (this.user.subscription_cancel_url) {
      router.push(this.user.subscription_cancel_url);
    }
    const options = {
      url: "/api/subscriptions/cancel-me",
      method: "POST",
    };
    return await axios(options)
      .then((response) => {
        alertStore.showSuccess("The cancellation was successful.");
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          alertStore.showError("You have to sign in first.");
          router.push({ name: "Login" });
        }
      });
  }

  return {
    authChecked,
    user,
    fetchUser,
    updateUser,
    logoutUser,
    fetchProfile,
    cancelSubscription,
    setUserRemainingCredit,
  };
});
