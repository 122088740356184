<template>
  <header class="absolute inset-x-0 top-0 z-50">
    <nav
      class="flex items-center justify-between p-2 xs:p-6 lg:px-8 max-w-3xl mx-auto"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <router-link
          :to="isSummarizationHomePage ? '/?from=s' : '/'"
          class="-m-1.5 p-1.5"
        >
          <span class="sr-only">NutshellPro</span>
          <div class="flex items-center">
            <img
              class="h-8 w-auto text-brand-800"
              :src="acronLogo"
              alt="NutshellPro"
            />
            <span class="ml-2 text-lg font-semibold text-brand-800">
              NutshellPro
            </span>
          </div>
        </router-link>
      </div>

      <div class="lg:flex lg:flex-1 sm:justify-end">
        <div class="ml-2 xs:ml-4 flex md:flex-shrink-0 md:items-center">
          <router-link
            v-if="authStore.user?.is_authenticated"
            to="/s"
            class="underline text-sm leading-6 text-gray-900"
          >
            Summarize
          </router-link>
          <router-link
            v-if="authStore.user?.is_authenticated"
            to="/user/account"
            class="underline text-sm leading-6 text-gray-900 ml-2 xs:ml-4"
          >
            Account
          </router-link>
          <router-link
            v-else
            to="/auth/login"
            class="text-sm font-semibold leading-6 text-gray-900"
          >
            Log in <span aria-hidden="true">&rarr;</span>
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>
<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores/authStore";

import acronLogo from "@/components/Navbars/acron-logo.svg";

const authStore = useAuthStore();

const router = useRouter();
const isSummarizationHomePage = computed(
  () => router.currentRoute.value.name === "SummarizationHome"
);
</script>
