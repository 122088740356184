<template>
  <div title="Share">
    <ShareIcon
      v-if="!resultsStore.summaryInfo.isPublic"
      class="h-6 w-6 cursor-pointer"
      @click="clickShareButton"
    />
    <ShareIconSolid
      v-else
      class="h-6 w-6 text-brand-800 cursor-pointer"
      @click="clickShareButton"
    />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useModalStore } from "@/stores/modalStore";
import { useAlertStore } from "@/stores/alertStore";

import { ShareIcon } from "@heroicons/vue/24/outline";
import { ShareIcon as ShareIconSolid } from "@heroicons/vue/24/solid";

const router = useRouter();
const resultsStore = useSummarizerResultsStore();
const modalStore = useModalStore();
const alertStore = useAlertStore();

const clickShareButton = async () => {
  // resultsStore.updateStream({ share: true }); // not implemented in backend
  if (!resultsStore.summaryInfo.isPublic) {
    modalStore.displayModal({
      title: "Share this summary",
      text: "Anyone with the URL will be able to view this summary.",
      textField: false,
      submitButton: true,
      closeButton: true,
      buttonText: "Confirm and Copy URL",
      action: () => {
        resultsStore.shareSummary();
      },
    });
  } else {
    navigator.clipboard
      .writeText(
        process.env.VUE_APP_BASE_URL + router.currentRoute.value.fullPath
      )
      .then(() => {
        alertStore.showSuccess("URL copied to clipboard.");
      });
  }
};
</script>
