<template>
  <div class="font-mono">
    <Navbar />
    <main class="isolate">
      <div class="relative pt-14">
        <div class="py-24 sm:py-32">
          <div class="mx-auto max-w-7xl">
            <div class="mx-auto text-center px-6 lg:px-8">
              <h1 class="text-4xl sm:text-7xl font-bold tracking-tight">
                Videos/Audios in a Nutshell
              </h1>
              <h1 class="mt-6 lg:mx-48 text-lg sm:text-2xl leading-8">
                Upload any local video or audio file, and get a text summary of
                the key points. Skip the unnecessary details and get straight to
                the heart of the content.
              </h1>
              <h2 class="mx-auto mt-2 text-lg leading-8 text-gray-900">
                Get <span class="font-semibold">60 minutes for free</span>, then
                just a <span class="font-semibold">one-time fee</span>, no
                subscription!
              </h2>
              <GetStarted />
            </div>

            <Benefits />
            <Examples />
          </div>
        </div>
      </div>

      <Pricing :user="authStore.user" :minimal="false" />
      <FAQ />
      <CTA />
    </main>

    <MyFooter />
  </div>
</template>

<script setup>
import { computed, watch } from "vue";
import { useHead } from "@unhead/vue";
import { useAuthStore } from "@/stores/authStore";
import { inject } from "vue";

const saEvent = inject("saEvent");

const handleClick = () => {
  saEvent("click_button_suggest_topics_form_home");
};

import Navbar from "@/components/Navbars/Navbar.vue";
import GetStarted from "@/components/Contents/GetStarted.vue";
import Benefits from "@/components/Contents/Benefits.vue";
import Examples from "@/components/Contents/Examples.vue";
import Pricing from "@/components/Contents/Pricing.vue";
import FAQ from "@/components/Contents/FAQ.vue";
import CTA from "@/components/Contents/CTA.vue";
import MyFooter from "@/components/Footers/Footer.vue";
import { TITLE } from "@/constants";

const authStore = useAuthStore();

import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

const fromSummarizationHome = computed(() => route.query.from === "s");

watch(
  () => authStore.user,
  (userNewValue) => {
    if (authStore.authChecked) {
      if (authStore.user?.is_authenticated && !fromSummarizationHome.value) {
        router.push({ name: "SummarizationHome" });
      }
    }
  },
  { immediate: true }
);

useHead({
  title: TITLE,
});
</script>
