<template>
  <div></div>
</template>
<script>
// import axios from "axios";

// import { mapGetters, mapActions } from "vuex";

// export default {
//   computed: {
//     ...mapGetters(["user"]),
//   },
//   methods: {
//     ...mapActions(["setAlert", "fetchUser"]),
//   },
//   async created() {
//     let token = this.$route.query.token;
//     const options = {
//       method: "POST",
//       url: "/api/auth/verify",
//       data: {
//         token,
//       },
//     };
//     axios(options)
//       .then(() => {
//         const msg = "The verification was successful.";
//         this.setAlert({
//           type: "success",
//           message: msg,
//         });
//       })
//       .catch((error) => {
//         const msg =
//           error.response?.data.detail ||
//           "Something went wrong. Please try again";
//         this.setAlert({
//           type: "error",
//           message: msg,
//         });
//       });
//     this.$router.push({ name: "Home" });
//   },
// };
</script>
