<template>
  <div
    class="mx-auto mt-2 grid max-w-2xl grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-6 lg:mx-0 lg:max-w-none"
  >
    <div v-for="video in videos" :key="video.video_id">
      <div class="relative w-full">
        <a
          @click="
            handleClick(`https://www.youtube.com/watch?v=${video.video_id}`)
          "
          class="text-brand-700 cursor-pointer"
        >
          <img
            :src="video.thumbnail_url"
            alt=""
            class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover"
          />
          <div
            class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"
          />
          <div
            class="absolute bottom-1 right-1 bg-black bg-opacity-60 text-white text-xs px-0.5 py-0.5 rounded"
          >
            {{ formatDurationYoutubeVideo(video.duration) }}
          </div>
        </a>
      </div>
      <div class="max-w-[100%]">
        <div class="group relative mt-2 flex items-center gap-x-2">
          <h3
            class="text-sm font-medium leading-5 text-gray-900 group-hover:text-gray-600 overflow-ellipsis overflow-hidden line-clamp-2"
          >
            <a
              @click="
                handleClick(`https://www.youtube.com/watch?v=${video.video_id}`)
              "
              class="text-brand-700 cursor-pointer"
            >
              <span class="absolute inset-0" />
              {{ video.video_title }}
            </a>
          </h3>
        </div>
        <div
          class="flex items-center -mx-4 gap-x-4 text-xs rounded-md px-3 font-medium text-gray-600"
        >
          <span
            class="relative z-10 rounded-full px-1 py-1.5 font-medium text-gray-600 truncate grow"
          >
            {{ video.channel_title }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { inject } from "vue";
import { formatDurationYoutubeVideo } from "@/utils";
import { useSummarizerFormStore } from "@/stores/summarizer/summarizerFormStore";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";

const formStore = useSummarizerFormStore();
const resultsStore = useSummarizerResultsStore();

const saEvent = inject("saEvent");
const handleClick = (videoUrl) => {
  saEvent("click_explore_item");
  formStore.setVideoUrl(videoUrl);
  // TODO: there is a 1-2 seconds delay before the summary is loaded and nothing happens in the UI
  resultsStore.handleURLSubmit(videoUrl);
};

const videos = ref(null);

const getVideos = () => {
  const options = {
    url: "/api/videos/explore",
    method: "GET",
  };
  return axios(options).then((response) => {
    videos.value = response.data;
  });
};

onMounted(() => {
  getVideos();
});
</script>
