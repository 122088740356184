import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import { useRouter } from "vue-router";

export const useSummarizerHistoryStore = defineStore(
  "summarizerHistory",
  () => {
    const router = useRouter();
    const userSummaries = ref({});

    const fetchUserSummaries = async (
      { page, limit } = {
        page: 1,
        limit: 10,
      }
    ) => {
      const options = {
        url: "/api/summaries/get/recent/user",
        method: "GET",
        params: {
          page,
          limit,
        },
      };
      if (sessionStorage.getItem("titleUpdated")) {
        options.headers = {
          "Cache-Control": "no-cache",
        };
        sessionStorage.removeItem("titleUpdated");
      }
      return await axios(options)
        .then((response) => {
          userSummaries.value = response.data;
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            router.push({ name: "Login" });
          }
        });
    };

    return {
      userSummaries,
      fetchUserSummaries,
    };
  }
);
