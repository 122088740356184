<template>
  <div>
    <main>
      <router-view :logo="acronLogo" @social-login="socialLogin" />
    </main>
    <MyFooter />
  </div>
</template>
<script setup>
import { useHead } from "@unhead/vue";
import axios from "axios";
import { inject } from "vue";

import MyFooter from "@/components/Footers/Footer.vue";
import acronLogo from "@/components/Navbars/acron-logo.svg";

const saEvent = inject("saEvent");

const socialLogin = () => {
  const options = {
    method: "GET",
    url: "/api/auth/google/authorize",
  };

  axios(options)
    .then((response) => {
      let authorization_url = response.data.authorization_url;
      window.location.replace(authorization_url);
    })
    .catch((error) => {
      const msg =
        error.response?.data.detail || "Something went wrong. Please try again";
      console.log(msg);
    });
  saEvent("click_button_google_login");
};

useHead({
  title: "NutshellPro - Login",
});
</script>
