<template>
  <div class="font-mono">
    <Navbar />
    <main
      class="pt-14 mb-auto mx-auto max-w-md sm:max-w-3xl w-full px-6 lg:px-8"
    >
      <div class="mt-8 max-w-7xl mx-auto">
        <h3 class="text-lg leading-6 font-semibold text-gray-900">History</h3>
        <p class="mt-1 max-w-2xl text-xs sm:text-base text-gray-900">
          View your summaries history
        </p>
      </div>
      <SummariesHistoryContainer
        :summaries="historyStore.userSummaries.summaries"
      />
      <Pagination
        v-if="shouldDisplayPagination"
        :currentPage="Number(props.page)"
        :totalPages="historyStore.userSummaries?.total_pages"
        :totalItems="historyStore.userSummaries?.total_summaries"
        :onPageChange="handlePageChange"
      />
    </main>

    <MyFooter />
  </div>
</template>
<script setup>
import { computed, onMounted } from "vue";
import { useHead } from "@unhead/vue";
import { useRouter } from "vue-router";
import { useSummarizerHistoryStore } from "@/stores/summarizer/summarizerHistoryStore";

import Navbar from "@/components/Navbars/Navbar.vue";
import MyFooter from "@/components/Footers/Footer.vue";
import SummariesHistoryContainer from "@/components/SummariesHistory/SummariesHistoryContainer.vue";
import Pagination from "@/components/Pagination/Pagination.vue";

const props = defineProps({
  page: {
    type: String,
    default: "1",
  },
});

const historyStore = useSummarizerHistoryStore();

const router = useRouter();

onMounted(() => {
  historyStore.fetchUserSummaries({
    page: props.page || 1,
  });
});

const shouldDisplayPagination = computed(() => {
  return (
    props.page &&
    historyStore.userSummaries.total_pages > 1 &&
    historyStore.userSummaries.total_summaries > 0
  );
});

const handlePageChange = (page) => {
  historyStore.fetchUserSummaries({
    page,
  });

  router.push({
    query: {
      page,
    },
  });

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

useHead({
  title: "NutshellPro - History",
});
</script>
