<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <h2>NutshellPro Privacy Policy</h2>
    <p>
      Please read this document carefully. It is the NutshellPro Privacy Policy
      (the &ldquo;Privacy Policy&rdquo;) and explains how personal information
      is collected, used and disclosed by NutshellPro with respect to your
      access and use of this web site (located at www.nutshellpro.com) (the
      &ldquo;Site&rdquo;).
    </p>
    <p>
      Four Winds Software (&ldquo;NutshellPro&rdquo;, &ldquo;we&rdquo;,
      &ldquo;our&rdquo;, or &ldquo;us&rdquo;) reserves the right to change or
      modify this Privacy Policy from time to time, and we will notify users
      (&ldquo;you&rdquo;) of material changes on the Site before they take
      effect. We will occasionally update this Privacy Policy to reflect changes
      in our practices. If we make any material changes in the way we collect,
      use, and/or share your personal information, we will notify you by sending
      an email to the email address you most recently provided us in your
      account, profile or registration (unless we do not have such an email
      address), and/or by posting notice of the changes on the Site. Your use of
      the Site and any of the information, products or various services, if any
      (collectively, the &ldquo;Services&rdquo;) provided by us will be subject
      to the most current version of this Privacy Policy posted on the Site at
      the time of your use. We recommend that you check the site from time to
      time to inform yourself of any changes in this Privacy Policy or any of
      our other policies.
    </p>
    <h2>What Is Personal Information?</h2>
    <p>
      &ldquo;Personal information&rdquo; means information that specifically
      identifies an individual (such as a name, address, telephone number,
      mobile number or email address) or information about that individual that
      is directly linked to personally identifiable information. Personal
      information does not include &ldquo;aggregate&rdquo; information, which is
      data we collect about the use of the Site and the Services. Our Privacy
      Policy does not restrict or limit our collection and use of aggregate
      information.
    </p>
    <h2>What Personal Information Do We Collect?</h2>
    <p>
      We collect certain information you voluntarily provide to us including,
      without limitation, the information you enter when you create an account
      and profile, send us an email, request user support or submit or post any
      other content to the Site or use the Services.&nbsp;
    </p>
    <p>
      We automatically collect certain information when you use the Site and the
      Services including, without limitation, your Internet Protocol (IP)
      address, your operating system, the browser type, the address of a
      referring site and your activity on the Site. We also collect information
      from mobile phone users in a similar manner to optimize the mobile
      experience. Beyond mobile phone numbers, we collect mobile screen
      resolution, device make and manufacturer, as well as the device&rsquo;s
      unique identifier (in the iPhone&rsquo;s case, a UDID), and, with your
      permission, your location. We treat this information as personal
      information if we combine it with or link it to any of the identifying
      information mentioned above. Otherwise, it is used in the aggregate only.
    </p>
    <p>
      We may also receive personal information from third parties about their
      users, and may combine this information with other personal information we
      maintain about you. If we do so, this Privacy Policy will govern the
      combined information.
    </p>
    <p>
      The Site may now or in the future allow users to send messages to each
      other. These messages may be stored for retrieval at later times in order
      to facilitate user communication.
    </p>
    <p>
      The Site allows users to make transactions. Data will be securely stored
      in our site or in third party sites when a transaction is made, including
      the date and type of purchase made, your credit card number, expiration
      date, billing address and CW or security code number.
    </p>
    <p>
      Even after you remove information from your profile or delete your
      account, copies of that information may remain viewable elsewhere to the
      extent it has been shared with others, it was otherwise distributed
      pursuant to your privacy settings, or it was copied or stored by other
      users. If you post any of your information to a public area of the Site or
      the Services, please be aware that it is no longer &ldquo;personal
      information&rdquo; for purposes of this policy, and we or anyone else may
      use such information without restriction. If you provide access to
      personal or other information, including your email address or name, to
      other users through the application of any of our privacy controls or
      other settings, you hereby acknowledge and agree that NutshellPro has no
      control over how others may collect, use or disclose such information.
    </p>
    <h2>What Aggregated and Non-Personal Information Do We Collect?</h2>
    <p>
      We may use standard Internet technology, such as web beacons and other
      similar technologies, to track your use of the Site and the Services. We
      also may include web beacons in promotional email messages or newsletters
      to determine whether messages have been opened and acted upon. The
      information we obtain in this manner enables us to customize the services
      we offer visitors to our Site to deliver targeted advertisements and to
      measure the overall effectiveness of our online advertising, content,
      programming or other activities.
    </p>
    <p>
      When you visit the Site, we may assign your computer one or more cookies,
      to facilitate access to the Site and to personalize your online
      experience. Through the use of a cookie, we also may automatically collect
      information about your online activity on the Site, such as the web pages
      you visit, the links you click, and the searches you conduct on the Site.
      Most browsers automatically accept cookies, but you can usually modify
      your browser setting to decline cookies. If you choose to decline cookies,
      please note that you may not be able to log in or use some of the
      interactive features offered on the Site.
    </p>
    <h2>Children</h2>
    <p>
      Our services and products are not directed to children under the age of
      18. We never knowingly collect and/or process any personal data from
      children under this age directly. If we discover we have received personal
      data from a child without parental or legal consent, we will immediately
      take reasonable steps to delete that information as quickly as possible.
      If you believe we have any information from or about a child, please
      contact us at hello@nutshellpro.com with the subject: "Children". The Site
      is not directed at children. Any person who provides his or her personal
      information to use through the Site or the Services represents that he or
      she is older than 18 years of age.
    </p>
    <h2>How Do We Use the Personal Information We Collect?</h2>
    <p>In General we may use information that we collect about you to:</p>
    <ul>
      <li>Deliver the products and services that you have requested;</li>
      <li>Manage your account and provide you with customer support;</li>
      <li>
        Perform research and analysis about your use of, or interest in, our
        products, services or content, or products, services or content offered
        by others;
      </li>
      <li>
        Communicate with you by email, postal mail, telephone and/or mobile
        devices about products or services that may be of interest to you either
        from us or other third parties;
      </li>
      <li>Tailor your user experience;</li>
      <li>
        Develop and display third-party content and advertising tailored to your
        interests on the Site and other sites;
      </li>
      <li>Enforce our Terms and Conditions;</li>
      <li>Manage our business; and</li>
      <li>
        Perform functions as otherwise described to you at the time of
        collection.
      </li>
    </ul>
    <p>
      Financial Information. We may use financial information or payment method
      to process payment for any purchases, subscriptions or sales made on the
      Site or through the Services, to protect against or identify possible
      fraudulent transactions, and otherwise as needed to manage our business.
    </p>
    <p>
      Please review &ldquo;Your Choices About Collection and Use of Your
      Information&rdquo; below.
    </p>
    <h2>What Personal Information Do We Share With Third Parties?</h2>
    <p>
      We want you to understand when and with whom we may share personal or
      other information we have collected about you or your activities on the
      Site or while using the Services.
    </p>
    <p>
      Personal Information. We do not share your personal information with
      others except as indicated below or when we inform you and give you an
      opportunity to opt out of having your personal information shared. We may
      share personal information with:
    </p>
    <ul>
      <li>
        Authorized service providers: We may share your personal information
        with our authorized service providers that perform certain services on
        our behalf. These services may include fulfilling orders, processing
        credit card payments, providing customer service and marketing
        assistance, performing business analysis, supporting the functionality
        of the Site and the Services, and supporting surveys and other features
        offered through the Site. These service providers may have access to
        personal information needed to perform their functions but are not
        permitted to share or use such information for any other purposes.
      </li>
      <li>
        Other Situations. We also may disclose your information:
        <ul>
          <li>
            In response to a subpoena or similar investigative demand, a court
            order, or a request for cooperation from a law enforcement or other
            government agency; to establish or exercise our legal rights; to
            defend against legal claims; or as otherwise required by law. In
            such cases, we may raise or waive any legal objection or right
            available to us, in our sole discretion.
          </li>
          <li>
            When we believe disclosure is appropriate in connection with efforts
            to investigate, prevent, report or take other action regarding
            illegal activity, suspected fraud or other wrongdoing; to protect
            and defend the rights, property or safety of our company, our users,
            our employees, or others; to comply with applicable law or cooperate
            with law enforcement; or to enforce our website terms and conditions
            or other agreements or policies.
          </li>
          <li>
            In connection with a substantial corporate transaction, such as the
            sale of our business, a divestiture, merger, consolidation, or asset
            sale, or in the unlikely event of bankruptcy.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      Any third parties to whom we may disclose personal information may have
      their own privacy policies which describe how they use and disclose
      personal information. Those policies will govern use, handling and
      disclosure of your personal information once we have shared it with those
      third parties as described in this Privacy Policy. If you want to learn
      more about their privacy practices, we encourage you to visit the websites
      of those third parties. These entities or their servers may be located
      either inside or outside the United States.
    </p>
    <p>
      Aggregated and Non-Personal Information. We may share aggregated and
      non-personal information we collect under any of the above circumstances.
      We may also share it with third parties to develop and deliver targeted
      advertising on the Site, through the Services and on websites of third
      parties. We may combine non-personal information we collect with
      additional non-personal information collected from other sources. We also
      may share aggregated information with third parties, including advisors,
      advertisers and investors, for the purpose of conducting general business
      analysis. For example, we may tell our advertisers the number of visitors
      to our website and the most popular features or services accessed. This
      information does not contain any personal information and may be used to
      develop website content and services that we hope you and other users will
      find of interest and to target content and advertising.
    </p>
    <h2>Your Control</h2>
    <p>
      You can choose not to provide us with certain information, but that may
      result in you being unable to use certain features of the Site and the
      Services because such information may be required in order for you to
      register as a member; purchase products or services; participate in a
      survey; ask a question; or initiate other transactions on the Site or
      through the Services.
    </p>
    <p>
      When you register on the Site you may be given a choice as to whether you
      want to receive email messages and/or newsletters about product updates,
      improvements, special offers, or containing special distributions of
      content by us. At any time you can choose to no longer receive commercial
      or promotional emails or newsletters from us by using the email address
      associated with your account to send an email to
      <a href="mailto:hello@nutshellpro.com">hello@nutshellpro.com</a> with the
      word &ldquo;Unsubscribe&rdquo; in the subject line. You also will be given
      the opportunity, in any commercial email that we send to you, to opt out
      of receiving such messages in the future. It may take up to 10 days for us
      to process an opt-out request. We may send you other types of
      transactional and relationship email communications, such as service
      announcements, administrative notices, and surveys, without offering you
      the opportunity to opt out of receiving them. Please note that, changing
      information in your account, or otherwise opting out of receipt of
      promotional email communications will only affect future activities or
      communications from us. If we have already provided your information to a
      third party (such as a credit card processing partner) before you have
      changed your preferences or updated your information, you may have to
      change you preferences directly with that third party.
    </p>
    <p>
      You may tell us not to share your personal information with third parties
      for direct marketing purposes by using the email address associated with
      your account to send an email to
      <a href="mailto:hello@nutshellpro.com">hello@nutshellpro.com</a> with the
      words &ldquo;Remove from direct marketing&rdquo; in the subject line.
    </p>
    <h2>Links to Other Sites</h2>
    <p>
      The Site and the Services may contain links to other sites. Any personal
      information you provide on the linked pages is provided directly to that
      third party and is subject to that third party&rsquo;s privacy policy.
      This Privacy Policy does not apply to such linked sites, and we are not
      responsible for the content or privacy and security practices and policies
      of these sites or any other sites that are linked to from our Site.
    </p>
    <h2>What Steps Do We Take To Protect Your Information Online?</h2>
    <p>
      We take reasonable measures to protect your personal information in an
      effort to prevent loss, misuse and unauthorized access, disclosure,
      alteration and destruction. Please be aware, however, that despite our
      efforts, no security measures are perfect or impenetrable and no method of
      data transmission can be guaranteed against any interception or other type
      of misuse. If we become aware of a security systems breach, we may attempt
      to notify you electronically so that you can take appropriate protective
      steps. NutshellPro may post a notice through the Services if a security
      breach occurs. If this happens, you will need a web browser enabling you
      to access the Services. We may also send an email to you at the email
      address you have provided to use in these circumstances. Depending on
      where you live, you may have a legal right to receive notice of a security
      breach in writing.
    </p>
    <p>
      Users should also be aware of how they handle and disclose their personal
      information and should avoid sending personal information through insecure
      email. Please refer to the Federal Trade Commission&rsquo;s website at
      <a href="http://www.ftc.gov/bcp/menus/consumer/data.shm"
        >http://www.ftc.gov/bcp/menus/consumer/data.shm</a
      >
      for information about how to protect yourself against identity theft.
    </p>
    <h2>International Visitors</h2>
    <p>
      The Site and the Services are hosted in and provided from Canada. If you
      use the Site or the Services from the European Union or other regions with
      laws governing data collection and use that may differ from Canadian law,
      please note that you are transferring your personal data to Canada. Canada
      does not have the same data protection laws as the EU and some other
      regions. By providing your personal information, you consent to the
      transfer of your personal data to Canada and the use of your personal
      information, in accordance with this Privacy Policy.
    </p>
    <h2>Information Retention and Disposal</h2>
    <p>
      We may retain personal information for as long as we have a business need
      for it or as applicable laws or regulations or government orders require
      us to retain it. Additionally, the Site and/or the Services may enable you
      (or other users) to save information including information that you have
      made public or allowed certain users to access or see.
    </p>
    <h2>What Choices Do You Have Regarding the Use of Your Information?</h2>
    <p>
      You may &ldquo;opt out&rdquo; of receiving marketing or promotional emails
      from us by following the instructions in those emails or by emailing us at
      <a href="mailto:hello@nutshellpro.com">hello@nutshellpro.com</a>. If you
      opt out, we may still send you non-promotional emails, such as emails
      about your accounts or our ongoing business relations.
    </p>
    <h2>Questions?</h2>
    <p>
      If you have any questions about our Privacy Policy, please contact us at
      <a href="mailto:hello@nutshellpro.com">hello@nutshellpro.com</a>.
    </p>
    <p>Last Updated: March 4, 2023.</p>
  </div>
</template>
<style scoped>
h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1em;
}
p {
  margin-bottom: 1em;
}
ul {
  margin-left: 1em;
}
li {
  margin-bottom: 0.5em;
  list-style-type: disc;
}
</style>
