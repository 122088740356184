<template>
  <div class="font-mono">
    <Navbar />
    <main class="isolate">
      <div class="relative pt-14">
        <div class="py-10 sm:py-12">
          <div class="mx-auto max-w-md sm:max-w-3xl px-6 lg:px-8">
            <div class="mx-auto text-left">
              <SummarizerForm />
              <div class="text-left">
                <div class="lg:col-span-7 mt-8">
                  <SummarizerResults />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </main>
    <MyFooter />
  </div>
</template>
<script setup>
import { useHead } from "@unhead/vue";
import { onMounted } from "vue";

import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useSummarizerStatusStore } from "@/stores/summarizer/summarizerStatusStore";
import { TITLE } from "@/constants";

import Navbar from "@/components/Navbars/Navbar.vue";
import Modal from "@/components/Utils/Modal.vue";
import SummarizerForm from "@/components/Summarizer/SummarizerForm.vue";
import SummarizerResults from "@/components/Summarizer/SummarizerResults.vue";
import MyFooter from "@/components/Footers/Footer.vue";

const resultsStore = useSummarizerResultsStore();
const statusStore = useSummarizerStatusStore();

onMounted(async () => {
  if (statusStore.isFetchingExistingSummary) {
    await resultsStore.fetchExistingSummary(props.summarySlug);
  }
});

const props = defineProps({
  summarySlug: {
    type: String,
  },
});

useHead({
  title: TITLE,
});
</script>
