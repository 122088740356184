<template>
  <p class="text-base font-medium text-red-800">
    <span>{{ statusStore.connectionStatusMessage }}</span>
    <br />
    You can first convert your file to MP3 using
    <a
      @click="handleClick"
      class="underline font-bold text-brand-600 cursor-pointer"
    >
      a free online converter</a
    >
    and then upload it here.
  </p>
</template>

<script setup>
import { inject } from "vue";
import { useSummarizerStatusStore } from "@/stores/summarizer/summarizerStatusStore";

const saEvent = inject("saEvent");

const statusStore = useSummarizerStatusStore();

function handleClick() {
  saEvent("click_link_convert_file");
  window.open("https://sodaphonic.com/convert", "_blank");
}
</script>
