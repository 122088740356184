<template>
  <div :class="`mt-2 rounded-md ${alertProperties.bgColor} p-4`">
    <div class="flex">
      <div class="flex-shrink-0 hidden sm:block">
        <component :is="alertProperties.icon" />
      </div>
      <div class="ml-3">
        <component v-if="action" :is="getActionComponent(action)" />
        <p
          v-else
          :class="`text-base font-medium text-${alertProperties.color}-800`"
        >
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { XCircleIcon } from "@heroicons/vue/24/solid";
import SpinnerIcon from "@/components/Icons/SpinnerIcon.vue";

import UsageExceededAlert from "@/components/Alerts/UsageExceededAlert.vue";
import LargeFileAlert from "@/components/Alerts/LargeFileAlert.vue";

import {
  ALERT_ACTION_UPGRADE_ACCOUNT,
  ALERT_ACTION_LARGE_FILE,
  ALERT_TYPE_INFO,
  ALERT_TYPE_ERROR,
} from "@/constants";

const props = defineProps({
  message: {
    type: String,
    default: "",
  },
  action: {
    type: String,
    default: "",
  },
  type: {
    type: String,
  },
});

const ALERT_PROPERTIES_INITIAL = {
  color: "",
  icon: undefined,
};
const alertProperties = ref(ALERT_PROPERTIES_INITIAL);

watch(
  () => props.type,
  (newType, oldType) => {
    alertProperties.value = getAlertProperties(newType);
  }
);

onMounted(() => {
  alertProperties.value = getAlertProperties(props.type);
});

const getActionComponent = (actionType) => {
  switch (actionType) {
    case ALERT_ACTION_UPGRADE_ACCOUNT:
      return UsageExceededAlert;
    case ALERT_ACTION_LARGE_FILE:
      return LargeFileAlert;
  }
};

const getAlertProperties = (type) => {
  switch (type) {
    case ALERT_TYPE_INFO:
      return {
        color: "linen",
        bgColor: "bg-linen-100",
        icon: <SpinnerIcon height="4" width="4" color="black" />,
      };
    case ALERT_TYPE_ERROR:
      return {
        color: "red",
        bgColor: "bg-red-100",
        icon: <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />,
      };
  }
};
</script>
