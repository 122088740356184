<template>
  <div class="relative -z-10 mt-32 px-6 lg:px-8">
    <div class="mx-auto max-w-3xl text-center">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Video and Audio Summarization Made Simple
      </h2>
      <p class="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-900">
        Upload any local video or audio file, and get a text summary of the key
        points. Skip the unnecessary details and get straight to the heart of
        the content.
      </p>
      <p class="mx-auto mt-2 text-lg leading-8 text-gray-900">
        Get <span class="font-semibold">60 minutes for free</span>, then just a
        <span class="font-semibold">one-time fee</span>, no subscription!
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <router-link
          @click="handleClick"
          :to="authStore.user?.is_authenticated ? '/s' : '/auth/login'"
          class="rounded-md bg-brand-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
        >
          Get started
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import { inject } from "vue";
const saEvent = inject("saEvent");
const handleClick = () => {
  saEvent("click_button_get_started_cta");
};

import { useAuthStore } from "@/stores/authStore";
const authStore = useAuthStore();
</script>
