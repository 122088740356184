export function isuuid(text) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  if (regexExp.test(text)) {
    return true;
  }
  return false;
}

export function formatDurationUsage(seconds) {
  const sign = seconds < 0 ? "-" : "";
  const absSeconds = Math.abs(seconds);

  if (absSeconds === 0) {
    return "0";
  }

  const hours = Math.floor(absSeconds / 3600);
  const minutes = Math.floor((absSeconds % 3600) / 60);
  const remainingSeconds = absSeconds % 60;

  let result = sign;

  if (hours) {
    result += `${hours} hours`;
    if (minutes) {
      result += ` and ${minutes} minutes`;
    }
  } else if (minutes) {
    result += `${minutes} minutes`;
  } else {
    result += `${remainingSeconds} seconds`;
  }

  return result;
}

export const formatDurationYoutubeVideo = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = "";
  if (hours > 0) {
    result += `${hours.toString()}:`;
  }
  if (hours > 0 || minutes > 0) {
    if (hours > 0) {
      // If there are hours, pad minutes with zeros
      result += `${minutes.toString().padStart(2, "0")}:`;
    } else {
      // If there are no hours, don't pad minutes with zeros
      result += `${minutes.toString()}:`;
    }
  }
  result += remainingSeconds.toString().padStart(2, "0");
  return result;
};

function formatDate(dateString) {
  const parsedTimestamp = new Date(dateString);
  return parsedTimestamp.toISOString().split("T")[0];
}
