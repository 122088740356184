<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-md rounded-lg">
        <router-link to="/">
          <img class="mx-auto h-12 w-auto" :src="logo" alt="Logo" />
        </router-link>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p
          @click="togglePage"
          class="mt-2 text-center text-sm text-gray-600 cursor-pointer"
        >
          Or create one
        </p>
        <form @submit.prevent="handleLogin" class="space-y-6">
          <div>
            <label for="email" class="sr-only">Email address</label>
            <input
              v-model="email"
              id="email"
              name="email"
              type="email"
              required
              class="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input
              v-model="password"
              id="password"
              name="password"
              type="password"
              required
              class="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:z-10 sm:text-sm"
              placeholder="Password"
            />
          </div>
          <div>
            <button
              type="submit"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            >
              Sign in
            </button>
          </div>
          <p v-if="message" class="text-center text-sm">{{ message }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";

import { useRouter } from "vue-router";

const router = useRouter();

const email = ref("");
const password = ref("");
const message = ref("");

const togglePage = () => {
  router.push({ name: "RegisterEmail" });
};

const handleLogin = () => {
  const loginUrl = "/api/auth/jwt/login";
  const data = `grant_type=&username=${encodeURIComponent(
    email.value
  )}&password=${encodeURIComponent(
    password.value
  )}&scope=&client_id=&client_secret=`;

  axios
    .post(loginUrl, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
      },
    })
    .then(() => {
      message.value = "Logged in successfully!";
      router.push({ name: "SummarizationHome" });
    })
    .catch((error) => {
      console.log(error);
      message.value = `Error: ${
        (error.response && error.response.data && error.response.data.detail) ||
        "Failed to log in."
      }`;
    });
};

const props = defineProps({
  logo: String,
});
</script>
