<template>
  <div
    v-if="editing"
    class="w-full pb-0.5 mb-0.5 flex flex-row gap-2 border-b-2 rounded-md border-gray-900 border-dashed focus:ring-2 focus:ring-inset focus:ring-indigo-600"
  >
    <label for="title" class="sr-only" />
    <input
      ref="input"
      name="title"
      type="text"
      v-model="text"
      class="grow bg-inherit border-none p-0 text-xl font-semibold leading-7 text-gray-900 focus:outline-none focus:border-none focus:ring-0"
      @keydown.enter.prevent="onClickSave"
    />
    <button
      @click="onClickSave"
      class="rounded-md text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
    >
      <CheckIcon class="h-6 w-6 inline stroke-gray-900" aria-hidden="true" />
      <span class="sr-only">Save</span>
    </button>
  </div>
  <div
    v-else
    class="flex flex-row justify-between items-center gap-2 pb-1.5"
    @dblclick="onEditOnDoubleClick"
  >
    <slot></slot>
    <button v-if="editable" @click="onClickTitle">
      <PencilIcon class="h-5 w-5 inline" aria-hidden="true" />
      <span class="sr-only">Edit</span>
    </button>
  </div>
</template>
<script setup>
import { ref, nextTick, watchEffect } from "vue";
import { CheckIcon, PencilIcon } from "@heroicons/vue/24/solid";

const props = defineProps(["text", "editable", "onEdit", "onSave"]);

const editing = ref(false);
const input = ref(null);
const text = ref(props.text);

watchEffect(() => {
  text.value = props.text;
});

const onClickTitle = () => {
  editing.value = true;
  props?.onEdit();
  nextTick(() => {
    input.value.focus();
  });
};

const onEditOnDoubleClick = () => {
  if (props.editable) {
    onClickTitle();
  }
};

const onClickSave = async () => {
  await props.onSave(text.value, onSaved);
};

const onSaved = () => {
  editing.value = false;
};
</script>
