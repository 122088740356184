<template>
  <p class="text-base font-medium text-red-800">
    It looks like you don't have enough credits to complete this action.
    <br />
    <a
      @click="handleUpgradeNowClick"
      class="underline font-bold text-brand-600 cursor-pointer"
    >
      Upgrade for just $0.16 per hour of summarization
    </a>
    and keep enjoying our service with pay-as-you-go flexibility. No
    subscription!
  </p>
</template>

<script setup>
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment/paymentStore";

const payemntStore = usePaymentStore();
const router = useRouter();

function handleUpgradeNowClick() {
  payemntStore.setShowPricingInProfile(true);
  router.push({ name: "Profile" });
}
</script>
