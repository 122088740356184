/* global LemonSqueezy */
import { defineStore } from "pinia";
import axios from "axios";
import { router } from "@/main";

export const useLemonStore = defineStore("lemon", {
  state: () => ({
    lemonLoaded: false,
  }),
  actions: {
    async loadLemon() {
      if (this.lemonLoaded) return;

      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://app.lemonsqueezy.com/js/lemon.js";
        script.defer = true;
        script.onload = () => {
          this.lemonLoaded = true;
          resolve();
        };
        document.body.appendChild(script);
      });
    },
    getMySubscriptionStatus() {
      const options = {
        url: `/api/subscriptions/me`,
        method: "GET",
      };
      return axios(options)
        .then((response) => {
          // TODO: add a success alert
          router.push({ name: "SummarizationHome" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
