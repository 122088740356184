<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
      <div class="bg-white py-14 px-4 rounded-lg shadow">
        <router-link to="/">
          <img class="mx-auto h-12 w-auto" :src="logo" alt="" />
        </router-link>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-base text-gray-900">Or create one</p>
        <div class="mt-1 py-14 flex justify-center">
          <div>
            <button
              @click="$emit('social-login', 'google')"
              class="w-full inline-flex justify-center py-2 px-4 border border-brand-300 rounded-md shadow-sm bg-white text-base font-semibold text-brand-600 hover:bg-gray-50"
            >
              <span class="sr-only">Sign in with Google</span>
              <img class="w-15 h-5 mr-2" aria-hidden="true" :src="google" />
              Continue with Google
            </button>
          </div>
        </div>
        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-200" />
          </div>
          <div class="relative flex justify-center text-xs leading-6">
            <span class="bg-white px-6 text-gray-900"
              >By proceeding with account creation, you agree to our
              <router-link to="/page/privacy" class="text-brand-600">
                Terms of Use</router-link
              >
              and
              <router-link to="/page/privacy" class="text-brand-600">
                Privacy Policy
              </router-link>
              Privacy Policy.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import google from "@/assets/img/google.svg";

const props = defineProps({
  logo: String,
});
</script>

<script>
export default {
  emits: ["social-login"],
};
</script>
