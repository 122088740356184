<template>
  <div class="h-6 w-6 mr-2 cursor-pointer" title="Listen">
    <PlayCircleIcon
      v-if="!isAudioLoading && !isAudioPlaying"
      @click="getAudio"
    />
    <SpinnerIcon v-else-if="isAudioLoading" color="black" />
    <PlayCircleIconSolid
      v-else
      @click="stopAudio"
      class="animate-pulse text-brand-800 cursor-pointer"
    />
  </div>
</template>
<script setup>
import { ref, onUnmounted } from "vue";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useAlertStore } from "@/stores/alertStore";

import SpinnerIcon from "@/components/Icons/SpinnerIcon.vue";
import { PlayCircleIcon } from "@heroicons/vue/24/outline";
import { PlayCircleIcon as PlayCircleIconSolid } from "@heroicons/vue/24/solid";

const resultsStore = useSummarizerResultsStore();
const alertStore = useAlertStore();

const isAudioPlaying = ref(false);
const isAudioLoading = ref(false);
const audioSrc = ref(null);
const audio = ref(null);

const getAudio = async () => {
  if (isAudioLoading.value || isAudioPlaying.value) {
    return;
  }
  if (audio.value) {
    playAudio();
    return;
  }
  isAudioLoading.value = true;
  resultsStore.updateStream({ audio_requested: true });

  await resultsStore
    .fetchAudio(resultsStore.summaryInfo.slug)
    .then((response) => {
      const blob = new Blob([response.data], { type: "audio/mp3" });
      audioSrc.value = URL.createObjectURL(blob);
      audio.value = new Audio(audioSrc.value);
      audio.value.addEventListener("play", () => {
        isAudioLoading.value = false;
        isAudioPlaying.value = true;
      });
      audio.value.addEventListener("ended", () => {
        isAudioPlaying.value = false;
      });
      playAudio();
    })
    .catch((error) => {
      alertStore.showError("Something went wrong. Please try again later.");
      isAudioLoading.value = false;
      isAudioPlaying.value = false;
    });
  isAudioLoading.value = false;
};

const playAudio = async () => {
  if (audio.value) {
    audio.value.play();
    isAudioPlaying.value = true;
  }
};

const stopAudio = async () => {
  if (audio.value) {
    audio.value.pause();
    audio.value.currentTime = 0;
    isAudioPlaying.value = false;
  }
};

onUnmounted(() => {
  stopAudio();
});
</script>
