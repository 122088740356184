<template>
  <div class="py-18">
    <div
      :class="[
        payemntStore.pricingMinimalUI ? 'px-0' : 'px-6 lg:px-8',
        'mx-auto max-w-7xl',
      ]"
    >
      <div
        v-if="!payemntStore.pricingMinimalUI"
        class="mx-auto max-w-4xl text-center"
      >
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900">
          Simple and Straightforward Pricing
        </p>
      </div>
      <p
        v-if="!payemntStore.pricingMinimalUI"
        class="mx-auto mt-6 max-w-3xl text-center text-sm sm:text-lg leading-6 sm:leading-8 text-gray-900"
      >
        Start with
        <span class="font-bold">1 hour of free video/audio summarization</span
        >,<br />
        then pay for what you use,
        <span class="font-bold">No Subscription</span>!
      </p>
      <div
        class="mx-auto mt-8 grid max-w-lg lg:max-w-4xl grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-y-0"
      >
        <div
          v-for="tier in payemntStore.tiers"
          :key="tier.id"
          class="flex flex-col justify-between relative bg-white shadow-2xl rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 mb-0 sm:mb-4"
        >
          <h3
            :id="tier.id"
            class="text-base font-semibold leading-7 text-brand-600"
          >
            {{ tier.name }}
          </h3>
          <p class="mt-4 flex items-baseline gap-x-2">
            <span
              v-if="tier.originalPrice"
              class="text-3xl text-gray-400 line-through"
            >
              {{ tier.originalPrice }}
            </span>
            <span class="text-5xl font-bold tracking-tight text-gray-900">
              {{ tier.discountedPrice || tier.price }}
            </span>
          </p>
          <p v-if="tier.originalPrice" class="mt-1 text-xs text-brand-600">
            Save
            {{
              (
                (1 -
                  tier.discountedPrice.slice(1) / tier.originalPrice.slice(1)) *
                100
              ).toFixed(0)
            }}% for a limited time!
          </p>
          <p class="mt-6 text-base font-bold leading-7 text-gray-600">
            {{ tier.description }}
          </p>
          <p class="text-base leading-7 text-gray-600">
            {{ tier.extendedDescription }}
          </p>
          <ul
            role="list"
            class="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10"
          >
            <li
              v-for="feature in tier.features"
              :key="feature"
              class="flex gap-x-3"
            >
              <CheckIcon
                class="h-6 w-5 flex-none text-brand-600"
                aria-hidden="true"
              />
              {{ feature }}
            </li>
          </ul>
          <button
            @click="payemntStore.handleBuyBottonClick(tier.id)"
            :aria-describedby="tier.id"
            class="bg-brand-600 text-white shadow-sm hover:bg-brand-500 mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
          >
            {{ tier.ctaText }}
          </button>
        </div>
        <div
          class="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center mt-2 sm:mt-6"
        >
          <div class="lg:min-w-0 lg:flex-1">
            <h3
              class="text-lg font-semibold leading-8 tracking-tight text-brand-600"
            >
              Business
            </h3>
            <p class="mt-1 text-base leading-7 text-gray-600">
              Contact us for a plan that scales with your rapidly growing
              business and get as much summarization hours as you need.
            </p>
          </div>
          <a
            href="https://tally.so/r/3E5zVl"
            target="_blank"
            class="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-brand-600 ring-1 ring-inset ring-brand-200 hover:ring-brand-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
          >
            Contact Us <span aria-hidden="true">&rarr;</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";

import { CheckIcon } from "@heroicons/vue/20/solid";

import { usePaymentStore } from "@/stores/payment/paymentStore";

const payemntStore = usePaymentStore();

const props = defineProps({
  minimal: {
    type: Boolean,
    default: true,
  },
});

onMounted(async () => {
  payemntStore.setPricingMinimalUI(props.minimal);

  const lastAttemptToBuy = localStorage.getItem("lastAttemptToBuy");
  if (lastAttemptToBuy) {
    const currentTime = new Date().getTime();
    // if less than 15 minutes have passed
    if (currentTime - lastAttemptToBuy <= 15 * 60 * 1000) {
      payemntStore.buyLemonSubscription(
        process.env.VUE_APP_LEMON_3HOURS_PRODUCT_ID
      );
    }
    localStorage.removeItem("lastAttemptToBuy");
  }
});
</script>
