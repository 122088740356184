<template>
  <dl class="space-y-10">
    <!-- video thumbnail and title -->
    <div
      class="mx-auto mt-2 grid max-w-2xl grid-cols-1 sm:grid-cols-3 sm:gap-x-4 gap-y-2 md:gap-y-6 lg:mx-0 lg:max-w-none"
    >
      <div class="relative w-full">
        <a
          v-if="!isuuid(resultsStore.videoInfo?.url)"
          :href="resultsStore.videoInfo?.url"
          target="_blank"
        >
          <img
            v-if="resultsStore.videoInfo?.thumbnail_url"
            :src="resultsStore.videoInfo?.thumbnail_url"
            alt=""
            class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover"
          />
          <div
            v-else-if="resultsStore.videoInfo"
            class="inset-0 flex items-center justify-center aspect-[16/9] w-full rounded-2xl bg-gray-300 object-cover"
          >
            <PlayCircleIcon class="h-12 w-12 text-gray-400" />
          </div>
          <div
            v-if="resultsStore.videoInfo"
            class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"
          />
          <div
            v-if="resultsStore.videoInfo?.duration"
            class="absolute bottom-1 right-1 bg-black bg-opacity-60 text-white text-xs px-0.5 py-0.5 rounded"
          >
            {{ formatDurationYoutubeVideo(resultsStore.videoInfo?.duration) }}
          </div>
        </a>
        <div
          v-else-if="resultsStore.videoInfo"
          class="inset-0 flex items-center justify-center aspect-[16/9] w-full rounded-2xl bg-gray-300 object-cover"
        >
          <PlayCircleIcon class="h-12 w-12 text-gray-400" />
        </div>
      </div>
      <div class="col-span-2 max-w-[100%]">
        <EditableText
          :editable="isTitleEditable()"
          :text="resultsStore.videoInfo?.title"
          :onSave="handleUpdateTitle"
          :onEdit="handleEditTitle"
        >
          <div class="group relative flex items-center gap-x-2">
            <h3
              class="text-sm font-medium leading-5 text-gray-900 group-hover:text-gray-600 overflow-ellipsis overflow-hidden line-clamp-2"
            >
              <dt
                class="text-xl font-semibold leading-7 text-gray-900 items-start overflow-ellipsis overflow-hidden line-clamp-2"
              >
                {{ resultsStore.videoInfo?.title }}
              </dt>
            </h3>
          </div>
        </EditableText>
        <div
          class="flex items-center -mx-4 gap-x-4 text-xs rounded-md px-3 font-medium text-gray-600"
        >
          <span
            class="text-base relative z-10 rounded-full px-1 font-medium text-gray-600 truncate grow"
          >
            {{ resultsStore.videoInfo?.website }}
          </span>
        </div>
      </div>
    </div>
    <!-- summary -->
    <dd
      v-if="
        resultsStore.summaryInfo.briefSummary ||
        resultsStore.summaryInfo.keyTakeaways ||
        resultsStore.summaryInfo.chapters
      "
      class="prose mt-2 text-base leading-7 text-gray-900 items-start"
    >
      <div ref="fullsummary" class="flex font-semibold gap-2">
        <PlayAudioButton />
        <div class="grow">Summary</div>
        <ShareButton />
        <SummarizerFeedback />
        <CopyButton :text="resultsStore.summaryInfo.briefSummary" />
      </div>
      <div
        class="mt-2"
        v-html="marked.parse(resultsStore.summaryInfo.briefSummary)"
      ></div>
      <div
        class="mt-2"
        v-html="marked.parse(resultsStore.summaryInfo.keyTakeaways)"
      ></div>
      <div
        v-for="(chapter, index) in resultsStore.summaryInfo.chapters"
        :key="chapter.chapter_start"
      >
        <div class="flex font-semibold gap-2">
          <div class="grow">
            {{ chapter.chapter_title || `Chapter ${index + 1}` }}
          </div>
          <CopyButton
            :text="chapter.brief_summary + '\n\n' + chapter.key_takeaways"
          />
        </div>
        <div class="mt-2" v-html="marked.parse(chapter.brief_summary)"></div>
        <div class="mt-2" v-html="marked.parse(chapter.key_takeaways)"></div>
      </div>
    </dd>
    <div
      v-if="statusStore.isResultsLoading"
      class="px-3 py-1 text-sm font-medium leading-none text-center text-brand-800 bg-linen-100 rounded-full animate-pulse"
    >
      summarizing ...
    </div>
  </dl>
</template>

<script setup>
import { inject } from "vue";
import { marked } from "marked";
import { useSummarizerResultsStore } from "@/stores/summarizer/summarizerResultsStore";
import { useSummarizerStatusStore } from "@/stores/summarizer/summarizerStatusStore";
import { isuuid, formatDurationYoutubeVideo } from "@/utils";

import ShareButton from "@/components/Summarizer/ShareButton.vue";
import CopyButton from "@/components/Summarizer/CopyButton.vue";
import PlayAudioButton from "@/components/Summarizer/PlayAudioButton.vue";
import SummarizerFeedback from "@/components/Summarizer/SummarizerFeedback.vue";
import { PlayCircleIcon } from "@heroicons/vue/24/solid";
import EditableText from "../EditableText/EditableText.vue";

const saEvent = inject("saEvent");

const resultsStore = useSummarizerResultsStore();
const statusStore = useSummarizerStatusStore();

const handleEditTitle = () => {
  saEvent("click_button_edit_title");
};

const handleUpdateTitle = (title, onUpdated) => {
  if (title.trim() === resultsStore.videoInfo?.title.trim()) {
    onUpdated();
    return;
  }
  resultsStore.updateTitle(title, onUpdated);
  saEvent("click_button_submit_edit_title");
};

const isTitleEditable = () => {
  return resultsStore.videoInfo?.isTitleEditable;
};
</script>
